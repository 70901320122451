import { create } from 'zustand';
import Globals from '@components/Globals';
import * as Sentry from '@sentry/react';
import api from '../services/api';

export const useAuthentication = create((set) => ({
  //States
  session: null,
  loading: false,
  token: null,
  methods: {
    signIn: async ({ email, password }) => {
      set({ loading: true });
      try {
        const { data } = await api.post('/authentication/sign-in', {
          email,
          password,
        });

        if (!data?.user || !data?.token) return false;

        set({ session: data.user, token: data.token });

        localStorage.setItem('token', data.token);
        api.defaults.headers.Authorization = `Bearer ${data.token}`;

        Sentry.setUser({
          id: data.user.id,
          email: data.user.email,
          agency_id: data.user.agency?.id,
        });

        //Ajuste temporário - Inicio
        Globals.userId = data.user.id;
        Globals.userName = data.user.firstName;
        Globals.userEmail = data.user.email;
        Globals.userStatus = data.user.role;
        Globals.userIdAgencia = data.user.agency?.id;
        Globals.userNameAgencia = data.user.agency?.nome_fantasia;
        Globals.userAllotmentAgencia = data.user.agency?.allotment;
        Globals.userAllotmentVencimentoAgencia = data.user.agency?.vencimento;
        Globals.userCreditoAgencia = data.user.agency?.credito;
        Globals.userCreditoUsadoAgencia = '0';
        Globals.userAllotmentUsadoAgencia = '0';

        if (data.user.imageUrl) {
          Globals.userImage = data.user.imageUrl;
        } else {
          if (data.user.image != null) {
            Globals.userImage = data.user.image;
          }
        }
        set({ loading: false });
        //Ajuste Temporário - Fim
        return true;
      } catch (err) {
        set({ loading: false });
        throw err?.message;
      }
    },
    signOut: async () => {
      set((prevState) => ({ ...prevState, session: null, token: null }));
      localStorage.removeItem('token');
    },
    validateToken: async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) return false;

        const { data } = await api.get('/authentication/validate');
        if (!data.user) return false;

        set({ session: data.user, token });

        Sentry.setUser({
          id: data.user.id,
          email: data.user.email,
          agency_id: data.user.agency?.id,
        });

        //Ajuste temporário - Inicio
        Globals.userId = data.user.id;
        Globals.userName = data.user.firstName;
        Globals.userEmail = data.user.email;
        Globals.userStatus = data.user.role;
        Globals.userIdAgencia = data.user.agency?.id;
        Globals.userNameAgencia = data.user.agency?.nomeFantasia;
        Globals.userAllotmentAgencia = data.user.agency?.allotment;
        Globals.userAllotmentVencimentoAgencia = data.user.agency?.vencimento;
        Globals.userCreditoAgencia = data.user.agency?.credito;
        Globals.userCreditoUsadoAgencia = '0';
        Globals.userAllotmentUsadoAgencia = '0';

        if (data.user.imageUrl) {
          Globals.userImage = data.user.imageUrl;
        } else {
          if (data.user.image != null) {
            Globals.userImage = data.user.image;
          }
        }
        set({ loading: false });
        //Ajuste Temporário - Fim
        return true;
      } catch (err) {
        set({ session: null, token: null });

        return false;
      }
    },
  },
  //Methods
}));
