import * as jose from 'jose';
import { useEffect, useState } from 'react';
import { BiSolidChevronRight } from 'react-icons/bi';
import Iframe from 'react-iframe';

import { MdOutlineHome } from 'react-icons/md';
import { Link, useSearchParams } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation.jsx';
import { PagePath } from '../pages-config.js';

function RelatorioVisualizacao() {
  const configMetabase = {
    url: 'https://book2b.metabaseapp.com',
    secret: '6890a03624e2188279aaa4f7e70a95ea2110b548a3df95e9d0c47f7754ecdd21',
  };
  const [token, setToken] = useState();
  const [searchParams] = useSearchParams();
  const report = Object.fromEntries(searchParams);
  const { name: mainTitle, id: reportId, type, ...params } = report;
  const [loading, setLoading] = useState(true);
  const [typeAlert] = useState('');
  const [titleAlert] = useState('');
  const [txtAlert] = useState('');

  const generateToken = async () => {
    const secret = new TextEncoder().encode(configMetabase.secret);
    const alg = 'HS256';
    const payload = {
      resource: {
        [type]: Number(reportId),
      },
      params: params || {},
    };

    let generatedToken = await new jose.SignJWT(payload)
      .setProtectedHeader({ alg })
      .setExpirationTime('2h')
      .sign(secret);

    setToken(generatedToken);
    setLoading(false);
  };

  useEffect(() => {
    generateToken();
  }, []);

  const reportEmbed = token
    ? `${configMetabase.url}/embed/${type}/${token}#theme=transparent&bordered=false&titled=false`
    : null;

  return (
    <>
      {loading && <LoadingAnimation />}
      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>
      <div className="breadCrumb mx-auto my-0">
        <MdOutlineHome className="icoBread" />
        <BiSolidChevronRight className="caretRight" /> &nbsp;&nbsp;
        <Link to={PagePath.Relatorios} relative="path">
          Relatórios
        </Link>{' '}
        &nbsp;/&nbsp;
        {mainTitle}
      </div>

      <div className="lineButtons row ">
        <div className="col">
          <h1>{mainTitle}</h1>
        </div>
      </div>

      <div className="contentAll">
        <Iframe url={reportEmbed} width="100%" height="900px" />
      </div>
    </>
  );
}

export default RelatorioVisualizacao;
