import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BiSolidChevronRight } from 'react-icons/bi';
import { MdOutlineHome } from 'react-icons/md';
import { GoAlert } from 'react-icons/go';
import api from '@services/api';
import { toaster } from '@system/utils/toaster';
import { Suppliers } from '@system/enums';
import { PagePath } from '../pages-config';
import PageLoading from '../components/PageLoading';
import './ReservasExternasPesquisar.scss';
import ConferenciaReserva from './ConferenciaReserva';

export default function ReservasExternasPesquisar() {
  const [reservationLoading, setReservationLoading] = useState(false);
  const [reservation, setReservation] = useState({});
  const [selectedSupplier, setSelectedSupplier] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    try {
      setReservationLoading(true);
      const { supplier, code } = formData;
      setSelectedSupplier(supplier);
      let response;

      if (supplier === Suppliers.Azul) {
        response = await api(`integrations/azul/reservations/${code}`);
      } else if (supplier === Suppliers.ETS) {
        response = await api(`integrations/ets/reservations/${code}`);
      } else if (supplier === Suppliers.Gol) {
        response = await api(`integrations/gol/reservations/${code}`);
      }

      setReservation(response.data);
      setReservationLoading(false);
    } catch (err) {
      setReservationLoading(false);
      setReservation({});
      if (err.statusCode == 404) toaster('error', 'Reserva não encontrada.');
      else
        toaster(
          'error',
          'Problemas ao realizar a busca. Tente novamente mais tarde.',
        );
    }
  };

  return (
    <>
      <div className="breadCrumb mx-auto my-0">
        <MdOutlineHome className="icoBread" />
        <BiSolidChevronRight className="caretRight" />{' '}
        <Link to={PagePath.Home} relative="path">
          Home
        </Link>
        &nbsp; / Conferência de reservas
      </div>
      <div className="mx-auto mb-5 mt-8 max-w-screen-2xl">
        <h1 className="m-0 text-heading-1 text-primary">
          Conferência de reservas
        </h1>
      </div>
      <div className="mx-auto my-0 max-w-screen-2xl rounded-md bg-white p-5 shadow-sm">
        <h2 className="text-heading-2 text-primary">Dados da reserva</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col content-end justify-between gap-x-2.5 lg:flex-row">
            <div className="mt-auto basis-2/5">
              <label htmlFor="code" className="text-sm font-medium">
                Código da reserva
              </label>
              <input
                type="text"
                id="code"
                {...register('code', { required: true })}
                placeholder="Digite o código da reserva"
              />
              <div className="lg:mt-2 lg:h-6">
                {errors.code && (
                  <>
                    <GoAlert className="mr-[5px] text-error-900" />
                    <span className="text-xs font-normal text-error-900">
                      Campo obrigatório
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="mt-auto basis-2/5">
              <label htmlFor="supplier" className="text-sm font-medium">
                Fornecedor
              </label>
              <select
                id="supplier"
                {...register('supplier', { required: true })}
              >
                <option value="">Escolha</option>
                {Object.entries(Suppliers).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <div className="lg:mt-2 lg:h-6">
                {errors.supplier && (
                  <>
                    <GoAlert className="mr-[5px] text-error-900" />
                    <span className="text-xs font-normal text-error-900">
                      Campo obrigatório
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="mt-auto basis-1/5 lg:mb-8">
              <button type="submit">Conferir reserva</button>
            </div>
          </div>
        </form>
      </div>
      {reservationLoading && (
        <div className="search-loading">
          <PageLoading />
          <span>Aguarde enquanto estamos buscando os resultados...</span>
        </div>
      )}

      {!reservationLoading && Object.keys(reservation).length !== 0 && (
        <ConferenciaReserva
          reservation={reservation}
          selectedSupplier={selectedSupplier}
        />
      )}
    </>
  );
}
