import { useNavigate } from 'react-router-dom';
import { PagePath } from '../pages-config';

const HeaderCheckout = () => {
  const navigate = useNavigate();

  function gohome() {
    navigate(PagePath.Home);
  }

  return (
    <header className="border-0 border-b-2 border-solid border-[#DCDCDC] px-6 py-4">
      <div className="">
        <div
          className="book2b"
          alt="Viagens Promo"
          onClick={() => gohome()}
        ></div>
      </div>
    </header>
  );
};

export default HeaderCheckout;
