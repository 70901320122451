import '../components/Login.css';
import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Globals from '../components/Globals';
import api from '@services/api';

const NovaSenha = () => {
  const [queryParams] = useSearchParams();

  const [endpoint] = useState(Globals.endPoint);
  const [idUser, setIdUser] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [titleError] = useState('');
  const [txtError, setTxtError] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [updatePass, setupdatePass] = useState(false);

  useEffect(() => {
    setIdUser(queryParams.get('uid'));
  }, [queryParams]);

  function validaForm(e) {
    e.preventDefault();
    setEmailError(false);
    setTxtError('');

    if (password != password2) {
      setEmailError(true);
      setTxtError('Senhas não conferem!');
      setTimeout(() => setTxtError(''), 5000);
    } else {
      setLoading(true);

      setTimeout(async function () {
        api
          .post(endpoint + '/authentication/reset-password', {
            new_password: password,
            user_id: idUser,
            token: queryParams.get('t'),
          })
          .then((response) => {
            if (response.status === 201) {
              setupdatePass(true);
            } else {
              setTypeAlert('alert-danger');
              settitleAlert('Usuário não encontrado!');
              setTimeout(() => setTypeAlert(''), 3000);
            }
          })
          .catch((error) => alert(error));
        setLoading(false);
      }, 1000);
    }
  }

  const handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      validaForm();
    }
  };

  return (
    <div id="login">
      <div className="col01">
        <div className="bg_01"></div>
      </div>

      <div className="col02">
        <div className="formLogin">
          <div
            className={txtError ? 'alert alert-danger ' : 'hide'}
            role="alert"
          >
            <h4 className={titleError ? '' : 'hide'}>{titleError}</h4>
            {txtError}
          </div>

          <div className="book2bLogin" alt="book2b">
            {' '}
          </div>

          <div className={idUser ? '' : 'hide'}>
            <form
              className={updatePass ? 'hide' : 'inputFormLogin'}
              onSubmit={(e) => validaForm(e)}
            >
              <div className="mb-5 mt-5">
                <h2>Redefinir Senha</h2>
                <p>Por favor, digite a nova senha.</p>
              </div>
              <div className="mt-5">
                <label>Nova Senha</label>
                <input
                  type="password"
                  className={emailError ? 'error' : ''}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Nova Senha"
                  required
                  onKeyDown={handleKeyDown}
                ></input>

                <label>Confirme a nova Senha</label>
                <input
                  type="password"
                  className={emailError ? 'error' : ''}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  placeholder="Nova Senha"
                  required
                  onKeyDown={handleKeyDown}
                ></input>

                <button type="submit">
                  <span className={loading ? 'hide' : ''}>Enviar</span>
                  <span className={loading ? 'loader' : 'hide'}></span>
                </button>
              </div>
            </form>

            <div className={updatePass ? 'mb-5 mt-5' : 'hide'}>
              <h2>Senha Alterada!</h2>
              <p></p>
              <p>Senha alterada com sucesso.</p>
            </div>
          </div>

          <div className={idUser ? 'hide' : 'mb-5 mt-5'}>
            Usuário não encontrado!
          </div>
        </div>
      </div>
    </div>
  );
};

export default NovaSenha;
