import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_ENDPOINT,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.code === 'ERR_NETWORK') {
      return Promise.reject('Não foi possivel conectar ao servidor');
    }

    throw error.response.data;
  },
);

export default api;
