import { useState, useEffect, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from '@components/breadcrumbs';
import { Button } from '@components/button';
import RouteCardDetailed from '@components/route-card/RouteCardDetailed';
import { SearchInput } from '@components/search-input';
import { Tab } from '@components/tab';
import api from '@services/api';
import { formatMonth } from '@system/utils/formatMonth';
import { Carousel } from '@trendyol-js/react-carousel';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import './AereoSaidas.scss';

export default function AereoSaidas() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [dataBase, setDataBase] = useState([]);
  const [dataBaseAeroportos, setDataBaseAeroportos] = useState([]);
  const [selectMonth, setSelectMonth] = useState('');
  const [, seListData] = useState([]);
  const [currentYear, setCurrentYear] = useState('');
  const [currentIndex, setIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  let startMonth = currentYear + '-' + selectMonth + '-01';
  let numDays = new Date(currentYear, selectMonth, 0).getDate();
  let finalMonth = currentYear + '-' + selectMonth + '-' + numDays;

  useEffect(() => {
    let query = 'SELECT * FROM `vpcharter_aeroportos` ';
    let fData = new FormData();
    fData.append('query', query);
    api
      .post('/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setDataBaseAeroportos(response.data);
        }
      })
      .catch((error) => alert(error));

    let Xmas95 = new Date();
    let currentMonth = Xmas95.getMonth() + 1;

    for (let i = 1; i < 13; i++) {
      let idSelect = 'btnMonth' + [i];
      document.getElementById(idSelect).className = '';
    }

    setCurrentYear(Xmas95.getFullYear());
    let idSelect = 'btnMonth' + currentMonth;
    document.getElementById(idSelect).className = 'selectMonth';
    document.getElementById(idSelect + 'b').className = 'selectMonth';
    document.getElementById(idSelect + 'c').className = 'selectMonth';
    document.getElementById(idSelect + 'd').className = 'selectMonth';
    document.getElementById(idSelect + 'e').className = 'selectMonth';

    setSelectMonth(currentMonth);
    loadPageInformations(currentMonth);
  }, []);

  function loadPageInformations(month) {
    setLoading(true);
    setDataBase([]);

    let Xmas95 = new Date();
    let currentYear = Xmas95.getFullYear();
    let currentMonth = Xmas95.getMonth() + 1;
    if (month < currentMonth) {
      currentYear = currentYear + 1;
    }

    let sub_array = [];
    for (let i = 1; i < 13; i++) {
      if (i >= currentMonth) {
        sub_array.push(i);
      }
    }

    for (let i = 1; i < currentMonth; i++) {
      if (i <= currentMonth) {
        sub_array.push(i);
      }
    }

    setCurrentYear(currentYear);

    let startMonth = currentYear + '-' + month + '-01';
    let numDias = new Date(currentYear, month, 0).getDate();
    let finalMonth = currentYear + '-' + month + '-' + numDias;

    let query = `
    SELECT
        r1.voo AS r1voo,
        r2.voo AS r2voo,
        r1.operadopor AS r1operadopor,
        r2.operadopor AS r2operadopor,
        vpcharter_fretamento.id AS id_fretamento,
        vpcharter_fretamento.bagagem AS bagagem,
        vpcharter_fretamento.rota_volta,
        vpcharter_fretamento.date_volta,
        vpcharter_fretamento.data_frete,
        vpcharter_fretamento.ativo,
        c1.nome AS r1cia,
        c1.logo AS r1logo,
        r1.id AS r1id,
        r1.origem AS r1origem,
        r1.saida AS r1saida,
        r1.destino AS r1destino,
        r1.chegada AS r1chegada,
        r1.outrodia AS r1outrodia,
        r1.escala AS r1escala,
        r1.conexao AS r1conexao,
        c2.nome AS r2cia,
        c2.logo AS r2logo,
        r2.id AS r2id,
        r2.origem AS r2origem,
        r2.saida AS r2saida,
        r2.destino AS r2destino,
        r2.chegada AS r2chegada,
        r2.outrodia AS r2outrodia,
        r2.escala AS r2escala,
        r2.conexao AS r2conexao,
        (
            SELECT vpcharter_escala.destino
            FROM vpcharter_escala
            WHERE vpcharter_escala.rota = r2.id
            ORDER BY vpcharter_escala.id
            LIMIT 1
        ) AS r2escaladestino,
        (
            SELECT vpcharter_escala.origem
            FROM vpcharter_escala
            WHERE vpcharter_escala.rota = r2.id
            ORDER BY vpcharter_escala.id
            LIMIT 1
        ) AS r2escalaorigem,
        (
            SELECT vpcharter_escala.destino
            FROM vpcharter_escala
            WHERE vpcharter_escala.rota = r1.id
            ORDER BY vpcharter_escala.id
            LIMIT 1
        ) AS r1escaladestino,
        (
            SELECT vpcharter_escala.origem
            FROM vpcharter_escala
            WHERE vpcharter_escala.rota = r1.id
            ORDER BY vpcharter_escala.id
            LIMIT 1
        ) AS r1escalaorigem,
        (
            SELECT COUNT(id) AS total
            FROM vpcharter_conexao
            WHERE vpcharter_conexao.rota = r1.id
        ) AS r1totalconexao,
        (
            SELECT COUNT(id) AS total
            FROM vpcharter_escala
            WHERE vpcharter_escala.rota = r1.id
        ) AS r1totalescala,
        (
            SELECT COUNT(id) AS total
            FROM vpcharter_escala
            WHERE vpcharter_escala.rota = r2.id
        ) AS r2totalescala,
        (
            SELECT vpcharter_conexao.aeroporto
            FROM vpcharter_conexao
            WHERE vpcharter_conexao.rota = r1.id
            ORDER BY vpcharter_conexao.id
            LIMIT 1
        ) AS r1conexaoaeroporto,
        (
            SELECT vpcharter_conexao.aeroporto
            FROM vpcharter_conexao
            WHERE vpcharter_conexao.rota = r2.id
            ORDER BY vpcharter_conexao.id
            LIMIT 1
        ) AS r2conexaoaeroporto,
        (
            SELECT COUNT(id) AS total
            FROM vpcharter_conexao
            WHERE vpcharter_conexao.rota = r2.id
        ) AS r2totalconexao
    FROM
        vpcharter_fretamento
        INNER JOIN vpcharter_rotas r1 ON r1.id = vpcharter_fretamento.rota_ida AND r1.ativo = 1
        INNER JOIN vpcharter_frota f1 ON f1.id = r1.aeronave
        INNER JOIN vpcharter_companhia c1 ON c1.id = f1.companhia
        LEFT JOIN vpcharter_rotas r2 ON r2.id = vpcharter_fretamento.rota_volta AND r2.ativo = 1
        LEFT JOIN vpcharter_frota f2 ON f2.id = r2.aeronave
        LEFT JOIN vpcharter_companhia c2 ON c2.id = f2.companhia
    WHERE
        vpcharter_fretamento.data_frete BETWEEN '${startMonth}' AND '${finalMonth}'
    ORDER BY
        vpcharter_fretamento.data_frete`;

    let fData = new FormData();
    fData.append('query', query);

    api
      .post('/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setDataBase(response.data);
          seListData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }

  function registerNewDeparture() {
    navigate(PagePath.AereoSaidasCadastrar);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      setSearchTerm(searchTermRef.current.value);
    }
  };

  function changeMonth(month) {
    // Limpa as classes de todos os botões
    for (let i = 1; i <= 12; i++) {
      for (let suffix of ['', 'b', 'c', 'd', 'e']) {
        document.getElementById(`btnMonth${i}${suffix}`).className = '';
      }
    }

    // Adiciona a classe de seleção para o mês especificado
    for (let suffix of ['', 'b', 'c', 'd', 'e']) {
      document.getElementById(`btnMonth${month}${suffix}`).className =
        'selectMonth';
    }

    setSelectMonth(month);
    loadPageInformations(month);
  }

  const handleChange = (newIndex) => setIndex(newIndex);

  const filteredFlights = dataBase.filter((flight) => {
    return (
      flight?.r1cia?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      flight?.r1origem?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      flight?.r1destino?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      String(flight?.rota_volta).includes(searchTerm) ||
      flight?.date_volta?.includes(searchTerm) ||
      flight?.r2cia?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      flight?.r2origem?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
      flight?.r2destino?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  });

  const filterQuantity = filteredFlights.filter((data) =>
    (data.ativo === currentIndex) == 1 ? 0 : 1,
  ).length;

  const searchTermRef = useRef();

  const handleSearch = () => {
    setSearchTerm(searchTermRef.current.value);
  };

  return (
    <div className="w-full">
      {loading && <LoadingAnimation />}
      <div className="flex flex-col">
        <BreadCrumbs
          paths={[
            { label: 'Aéreo', link: PagePath.Aereo },
            { label: 'Saídas', link: PagePath.AereoSaidas },
          ]}
        />
        <div className="mb-[25px] mt-[10px] flex w-full items-center justify-between">
          <h1 className="m-0 text-heading-1 text-primary">Saídas</h1>
          <Button
            label="Criar nova saída"
            className="w-max"
            onClick={registerNewDeparture}
          />
        </div>
      </div>

      <div className="w-full rounded-md bg-white p-4">
        <h3 className="!text-heading-3 !text-primary">
          Escolha o mês da data de saída
        </h3>
        <div className="w-full">
          <div className="monthCarousel carousel_1">
            <Carousel
              show={8.5}
              slide={2}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_2">
            <Carousel
              show={6.5}
              slide={2}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1b">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2b">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3b">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4b">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5b">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6b">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7b">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8b">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9b">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10b">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11b">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12b">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_3">
            <Carousel
              show={5.5}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1c">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2c">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3c">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4c">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5c">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6c">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7c">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8c">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9c">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10c">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11c">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12c">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_4">
            <Carousel
              show={4.5}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1d">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2d">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3d">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4d">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5d">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6d">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7d">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8d">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9d">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10d">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11d">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12d">
                Dezembro
              </button>
            </Carousel>
          </div>

          <div className="monthCarousel carousel_5">
            <Carousel
              show={2}
              infinite={false}
              swiping={true}
              responsive={true}
            >
              <button onClick={() => changeMonth('1')} id="btnMonth1e">
                Janeiro
              </button>
              <button onClick={() => changeMonth('2')} id="btnMonth2e">
                Fevereiro
              </button>
              <button onClick={() => changeMonth('3')} id="btnMonth3e">
                Março
              </button>
              <button onClick={() => changeMonth('4')} id="btnMonth4e">
                Abril
              </button>
              <button onClick={() => changeMonth('5')} id="btnMonth5e">
                Maio
              </button>
              <button onClick={() => changeMonth('6')} id="btnMonth6e">
                Junho
              </button>
              <button onClick={() => changeMonth('7')} id="btnMonth7e">
                Julho
              </button>
              <button onClick={() => changeMonth('8')} id="btnMonth8e">
                Agosto
              </button>
              <button onClick={() => changeMonth('9')} id="btnMonth9e">
                Setembro
              </button>
              <button onClick={() => changeMonth('10')} id="btnMonth10e">
                Outubro
              </button>
              <button onClick={() => changeMonth('11')} id="btnMonth11e">
                Novembro
              </button>
              <button onClick={() => changeMonth('12')} id="btnMonth12e">
                Dezembro
              </button>
            </Carousel>
          </div>
        </div>

        <Fragment>
          <Tab value={currentIndex} onChange={handleChange}>
            <div className="mt-2 flex w-full flex-col rounded-md border border-solid !border-neutral-400">
              <Tab.Heads>
                <Tab.Item label="Ativas" index={0} />
                <Tab.Item label="Inativas" index={1} />
              </Tab.Heads>
              <Tab.ContentWrapper>
                <Tab.Content index={0}>
                  <div className="flex w-full flex-col gap-5">
                    <div className="grid w-full grid-cols-2 items-center self-end">
                      {filteredFlights.length > 0 ? (
                        <span className="text-heading-3 text-primary">
                          {filterQuantity} resultados encontrados para{' '}
                          <span className="text-secondary">
                            {formatMonth(selectMonth)}
                          </span>
                        </span>
                      ) : (
                        <span className="text-heading-3 text-primary">
                          Não encontramos resultados para{' '}
                          <span className="text-secondary">
                            {formatMonth(selectMonth)}
                          </span>
                        </span>
                      )}
                      <div className="w-[328px] place-self-end">
                        <SearchInput
                          placeholder="Procurar por Origem ou Destino"
                          ref={searchTermRef}
                          onChange={(event) => {
                            if (event.target.value.trim().length === 0) {
                              loadPageInformations(selectMonth);
                              setSearchTerm('');
                            }
                          }}
                          onKeyDown={handleKeyDown}
                          handleSearch={handleSearch}
                        />
                      </div>
                    </div>
                    {filteredFlights
                      .filter((data) => data.ativo === 1)
                      .map((data, index) => (
                        <RouteCardDetailed
                          key={index}
                          baggage={data.bagagem}
                          airports={dataBaseAeroportos}
                          departureOperator={data.r1cia}
                          departureFlight={data.r1voo}
                          departureCompanyLogo={data.r1logo}
                          departureOrigin={data.r1origem}
                          departureOriginTime={data.r1saida}
                          departureOriginDate={data.data_frete}
                          departureDestination={data.r1destino}
                          departureDestinationTime={data.r1chegada}
                          departureDestinationDate={data.data_frete}
                          returnOperator={data.r2cia}
                          returnFlight={data.r2voo}
                          returnCompanyLogo={data.r2logo}
                          returnOrigin={data.r2origem}
                          returnOriginTime={data.r2saida}
                          returnOriginDate={data.date_volta}
                          returnDestination={data.r2destino}
                          returnDestinationTime={data.r2chegada}
                          returnDestinationDate={data.data_frete}
                          showPriceButton
                          showEditButton
                          {...data}
                        />
                      ))}
                  </div>
                </Tab.Content>
                <Tab.Content index={1}>
                  <div className="flex w-full flex-col gap-5">
                    <div className="grid w-full grid-cols-2 items-center">
                      {filteredFlights.length > 0 ? (
                        <span className="text-heading-3 text-primary">
                          {filterQuantity} resultados encontrados para{' '}
                          <span className="text-secondary">
                            {formatMonth(selectMonth)}
                          </span>
                        </span>
                      ) : (
                        <span>
                          Não encontramos resultados para{' '}
                          {formatMonth(selectMonth)} de {currentYear}
                        </span>
                      )}

                      <div className="w-[328px] place-self-end">
                        <SearchInput
                          placeholder="Procurar por Origem ou Destino"
                          ref={searchTermRef}
                          onChange={(event) => {
                            if (event.target.value.trim().length === 0) {
                              loadPageInformations(selectMonth);
                              setSearchTerm('');
                            }
                          }}
                          onKeyDown={handleKeyDown}
                          handleSearch={handleSearch}
                        />
                      </div>
                    </div>
                    {filteredFlights
                      .filter((data) => data.ativo === 0)
                      .map((data, index) => (
                        <RouteCardDetailed
                          key={index}
                          baggage={data.bagagem}
                          airports={dataBaseAeroportos}
                          departureOperator={data.r1cia}
                          departureFlight={data.r1voo}
                          departureCompanyLogo={data.r1logo}
                          departureOrigin={data.r1origem}
                          departureOriginTime={data.r1saida}
                          departureOriginDate={data.data_frete}
                          departureDestination={data.r1destino}
                          departureDestinationTime={data.r1chegada}
                          departureDestinationDate={data.data_frete}
                          returnOperator={data.r2cia}
                          returnFlight={data.r2voo}
                          returnCompanyLogo={data.r2logo}
                          returnOrigin={data.r2origem}
                          returnOriginTime={data.r2saida}
                          returnOriginDate={data.date_volta}
                          returnDestination={data.r2destino}
                          returnDestinationTime={data.r2chegada}
                          returnDestinationDate={data.data_frete}
                          showEditButton
                          {...data}
                        />
                      ))}
                  </div>
                </Tab.Content>
              </Tab.ContentWrapper>
            </div>
          </Tab>
        </Fragment>
      </div>
    </div>
  );
}
