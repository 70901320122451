import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Dashboard } from '@layout';
import { useAuthentication } from '@stores/authentication';
import { PagePath } from './pages-config';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { session, methods } = useAuthentication();

  useEffect(() => {
    const validate = async () => {
      const tokenIsValid = await methods.validateToken();
      if (!tokenIsValid) {
        navigate('/');
      } else {
        if (location.pathname === '/') {
          navigate(PagePath.Home);
        }
      }
    };

    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    session && (
      <Dashboard>
        <Outlet />
      </Dashboard>
    )
  );
}

export default App;
