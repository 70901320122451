import { useState } from 'react';
import { CgLogOut } from 'react-icons/cg';
import { HiMenu, HiX } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '@stores/authentication';
import { PagePath } from '../pages-config';

const Header = () => {
  const [opemMenu, setOpemMenu] = useState(false);
  const navigate = useNavigate();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { session, methods } = useAuthentication();

  function openMenu() {
    if (
      document.getElementById('sidebar').style.display == 'none' ||
      document.getElementById('sidebar').style.display == ''
    ) {
      document.getElementById('sidebar').style.display = 'block';
      setOpemMenu(true);
    } else {
      document.getElementById('sidebar').style.display = '';
      setOpemMenu(false);
    }
  }

  const logOut = () => {
    setShowUserMenu(false);
    methods.signOut();
    navigate('/');
  };

  const meusDados = () => {
    setShowUserMenu(false);
    navigate(PagePath.MeusDados);
  };

  function gohome() {
    navigate(PagePath.Home);
  }

  const fullName = session
    ? [session.firstName, session.familyName].join(' ')
    : '';

  return (
    <header>
      <div className="flex w-full justify-between p-2.5">
        <div
          className="h-7 w-56 cursor-pointer bg-[url('/src/assets/book2b.svg')] bg-no-repeat"
          alt="Viagens Promo"
          onClick={() => gohome()}
        ></div>
        <div
          className="cursor-pointer"
          onClick={() => setShowUserMenu(!showUserMenu)}
        >
          <span className="hidden align-middle text-sm md:inline-block">
            {fullName}
          </span>{' '}
          <HiMenu
            className={
              !opemMenu ? 'block cursor-pointer text-2xl md:hidden' : 'hidden'
            }
            id="hamburger"
            onClick={() => openMenu()}
          />
          <HiX
            className={
              opemMenu ? 'block cursor-pointer text-2xl md:hidden' : 'hidden'
            }
            id="hix"
            onClick={() => openMenu()}
          />
        </div>

        <div
          className={
            showUserMenu
              ? 'absolute right-0 top-14 z-10 w-52 rounded bg-white text-sm shadow-md'
              : 'hidden'
          }
        >
          <div
            className="border-0 border-b border-solid border-gray-200 p-3"
            onClick={() => meusDados()}
          >
            Meus Dados
          </div>
          <div className="p-3" onClick={() => logOut()}>
            <CgLogOut /> Logout
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
