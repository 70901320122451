export function formatBaggage(baggageValue) {
  if (!hasBaggage(baggageValue)) return ' 0 ';
  const baggage = baggageValue.split('-');
  return `${baggage[0]} ${baggage[0] > 1 ? 'bagagens' : 'bagagem'} de ${baggage[1]} kg`;
}

export function hasBaggage(baggageValue) {
  if (!baggageValue || baggageValue[0] == 0) return false;
  return true;
}

export function formatBaggageByQuantityAndWeight(baggageValue) {
  if (!hasBaggage(baggageValue)) return '-';
  const baggage = baggageValue.split('-');
  return {
    quantity: baggage[0] == 1 ? `${baggage[0]} bagagem` : `${baggage[0]} bagagens`,
    weight: `${baggage[1]}kg`,
  };
}
