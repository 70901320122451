import { useState } from 'react';
import '../components/Login.css';
import api from '@services/api';
import Globals from '../components/Globals';

const RecuperarSenha = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [titleError, setTitleError] = useState('');
  const [txtError, setTxtError] = useState('');
  const [loading, setLoading] = useState(false);
  const [envioEmail, setEnvioEmail] = useState(false);

  const tryToSendEmail = async (e) => {
    try {
      e.preventDefault();
      setEmailError(false);
      setLoading(true);

      await api.post(Globals.endPoint + '/authentication/recover-password', {
        email,
      });

      setEnvioEmail(true);
    } catch (err) {
      setEmailError(true);
      setTitleError('Erro ao enviar o email');

      const msg =
        err.statusCode === 404
          ? 'Email não encontrado. Verifique se o email está correto.'
          : 'Houve um erro durante o envio do email. Tente novamente.';
      setTxtError(msg);
      setTimeout(() => setTxtError(''), 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      tryToSendEmail();
    }
  };

  return (
    <div id="login">
      <div className="col01">
        <div className="bg_01"></div>
      </div>

      <div className="col02">
        <div className="formLogin">
          <div
            className={txtError ? 'alert alert-danger ' : 'hide'}
            role="alert"
          >
            <h4 className={titleError ? '' : 'hide'}>{titleError}</h4>
            {txtError}
          </div>

          <div className="book2bLogin" alt="book2b">
            {' '}
          </div>

          <form
            className={envioEmail ? 'hide' : 'inputFormLogin'}
            onSubmit={(e) => tryToSendEmail(e)}
          >
            <div className="mb-5 mt-5">
              <h2>Recuperar Senha</h2>
              <p>Por favor, digite seu e-mail de cadastro.</p>
            </div>
            <div className="mt-5">
              <label>E-mail</label>
              <input
                type="email"
                className={emailError ? 'error' : ''}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu e-mail"
                required
                onKeyDown={handleKeyDown}
              ></input>
              <button type="submit">
                <span className={loading ? 'hide' : ''}>Enviar</span>
                <span className={loading ? 'loader' : 'hide'}></span>
              </button>
            </div>
          </form>

          <div className={envioEmail ? 'mb-5 mt-5' : 'hide'}>
            <h2>E-mail Enviado!</h2>
            <p></p>
            <p>
              Em instantes você receberá o link de recuperação no e-mail{' '}
              <b>{email}</b>.
            </p>
            <p>
              Caso não encontre na caixa de entrada, por favor verifique na
              caixa de spam.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecuperarSenha;
