import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import './styles/global.scss';
import { defaultConfig } from 'tailwind-variants';
import * as Sentry from '@sentry/react';
import tailwindConfig from '../tailwind.config.js';
import { router } from './routes';

defaultConfig.twMergeConfig = {
  twMergeConfig: {
    classGroups: {
      'font-size': [
        { text: Object.keys(tailwindConfig.theme.extend.fontSize) },
      ],
      'text-color': [{ text: Object.keys(tailwindConfig.theme.extend.colors) }],
    },
  },
};

const shouldTrack = ['production', 'demo'].includes(
  import.meta.env.VITE_REPORT_ENV,
);

// Sentry is disabled in non-production environments to save on quota
Sentry.init({
  enabled: shouldTrack,
  environment: import.meta.env.VITE_REPORT_ENV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: 'book2b@' + import.meta.env.VITE_APP_VERSION,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [import.meta.env.VITE_ENDPOINT],
  sampleRate: shouldTrack ? 0.9 : 0,
  tracesSampleRate: shouldTrack ? 0.5 : 0,
  replaysOnErrorSampleRate: shouldTrack ? 0.5 : 0,
  replaysSessionSampleRate: shouldTrack ? 0.1 : 0,
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <Toaster position="bottom-right" />
  </React.StrictMode>,
);
