import Tag from '../components/Tag';
import './ConferenciaReserva.scss';
import { formatDate, formatCurrency } from '@system/utils';
import { convertStatusReservation } from '@system/converters/convertStatusReservation';
import { Suppliers } from '@system/enums';

export default function ConferenciaReserva({ reservation, selectedSupplier }) {
  const status = reservation.status
    ? convertStatusReservation(reservation.status, selectedSupplier)
    : {};

  return (
    <>
      <div className="mx-auto my-0 mt-8 max-w-screen-2xl rounded-md bg-white p-5 shadow-sm">
        <h2 className="text-heading-2 text-primary">Detalhes da reserva</h2>
        <div className="row d-grid border-bottom mx-2 my-5 gap-2.5 py-5">
          <div className="reservation-code col col-5">
            <span className="mr-2 text-heading-3 text-primary">
              {' '}
              Código da reserva:{' '}
            </span>
            <span className="mr-5">{reservation.recordLocator}</span>
            {Object.keys(status).length > 0 && (
              <Tag value={status.value} type={status.type} />
            )}
          </div>
        </div>
        <div className="row d-grid border-bottom mx-2 gap-2.5 pb-5">
          <span>
            <span className="mr-2 text-heading-3"> Data da criação: </span>
            {formatDate(reservation.created)}
          </span>
          <span>
            <span className="mr-2 text-heading-3"> Valor total: </span>
            {formatCurrency(reservation.totals?.amount)}
          </span>
          {reservation?.agency?.name != '' && (
            <span>
              <span className="mr-2 text-heading-3"> Agência: </span>
              {reservation.agency?.name}
            </span>
          )}
        </div>
        <div className="row d-grid border-bottom mx-2 my-5 gap-2.5">
          <h3 className="col text-heading-3 text-primary">
            {' '}
            Lista de passageiros:{' '}
          </h3>
          <table className="tblPassageirosReserva mx-2 text-start">
            <thead>
              <tr>
                <td>
                  <h3 className="text-heading-3 text-primary"> Nome </h3>
                </td>
                {![Suppliers.ETS, Suppliers.Gol].includes(selectedSupplier) && (
                  <td>
                    <h3 className="text-heading-3 text-primary"> CPF </h3>
                  </td>
                )}
              </tr>
            </thead>
            <tbody>
              {reservation.passengers.map((passengers) => (
                <tr key={passengers.id}>
                  <td key={passengers.fullName}>{passengers.fullName}</td>
                  {![Suppliers.ETS, Suppliers.Gol].includes(
                    selectedSupplier,
                  ) && <td key={passengers.document}>{passengers.document}</td>}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
