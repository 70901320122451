import { useState, useEffect } from 'react';
import { BiSolidChevronRight, BiTrash } from 'react-icons/bi';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { BsFillPlusCircleFill, BsDashCircleFill } from 'react-icons/bs';
import { GiPlaneWing } from 'react-icons/gi';
import { MdOutlineHome, MdOutlineStar } from 'react-icons/md';
import { TfiLayoutWidthDefault } from 'react-icons/tfi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import api from '@services/api';
import Globals from '../components/Globals';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';

export default function AereoFrotaAssentos() {
  const [endpoint] = useState(Globals.endPoint);
  const navigate = useNavigate();
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [txtAlert, setTxtAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [fabricante, setFabricante] = useState('');
  const [aeronave, setAeronave] = useState('');
  const [logo, setLogo] = useState('');
  const [, seCompanhia] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [updateLabelColumn, setupdateLabelColumn] = useState('');
  const [updateLabelColumnClass, setupdateLabelColumnClass] = useState('');
  const [upadeFileira, setupdateFileira] = useState('');
  const [updateTipoFileira, setupdateTipoFileira] = useState('');
  const [updateIdFileira, setupdateIdFileira] = useState('');
  const [updateAssento] = useState('');
  const [updateIdAssento, setupdateIdAssento] = useState('');
  const [updateAssentoClasse] = useState('');
  const [selectClass, setSelectClass] = useState('primeiraClasse');
  const [primeiraClasseFileiras, setprimeiraClasseFileiras] = useState([]);
  const [primeiraClasseColunas, setprimeiraClasseColunas] = useState([]);
  const [totalprimeiraClasseFileiras, setTotalprimeiraClasseFileiras] =
    useState('');
  const [totalprimeiraClasseColunas, setTotalprimeiraClasseColunas] =
    useState('');
  const [premiumFileiras, setPremiumFileiras] = useState([]);
  const [premiumColunas, setPremiumColunas] = useState([]);
  const [totalPremiumFileiras, setTotalPremiumFileiras] = useState('');
  const [totalPremiumColunas, setTotalPremiumColunas] = useState('');
  const [executivaFileiras, setExecutivaFileiras] = useState([]);
  const [executivaColunas, setExecutivaColunas] = useState([]);
  const [totalExecutivaFileiras, setTotalExecutivaFileiras] = useState('');
  const [totalExecutivaColunas, setTotalExecutivaColunas] = useState('');
  const [economicaFileiras, setEconomicaFileiras] = useState([]);
  const [economicaColunas, setEconomicaColunas] = useState([]);
  const [totalEconomicaFileiras, setTotalEconomicaFileiras] = useState('');
  const [totalEconomicaColunas, setTotalEconomicaColunas] = useState('');
  const [minFileiras] = useState([
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
  ]);
  const [servicoBordo, setServicoBordo] = useState('');
  const [caracteristicasAssentos, setCaracteristicasAssentos] = useState('');
  const [distancia, setDistancia] = useState('');
  const [largura, setLargura] = useState('');
  const [angulo, setAngulo] = useState('');
  const [caracteristicasassentosData, setCaracteristicasassentosData] =
    useState([]);
  const [caracteristicasAssentoGrupo, setCaracteristicasAssentoGrupo] =
    useState('');
  const [servicoBordoData, setServicoBordoData] = useState([]);
  const [assentoClasseData, setAssentoClasseData] = useState([]);
  const [corredoresPrimC, setCorredoresPrimC] = useState([]);
  const [corredoresPremium, setCorredoresPremium] = useState([]);
  const [corredoresExecutiva, setCorredoresExecutiva] = useState([]);
  const [corredoresEconomica, setCorredoresEconomica] = useState([]);

  const [saidaEmergPrimC, setSaidaEmergPrimC] = useState([]);
  const [saidaEmergPremium, setSaidaEmergPremium] = useState([]);
  const [saidaEmergExecutiva, setSaidaEmergExecutiva] = useState([]);
  const [saidaEmergEconomica, setSaidaEmergEconomica] = useState([]);

  const [selectCorredor, setSelectCorredor] = useState('');
  const [selectFileira, setSelectFileira] = useState('');
  const [nomenPC, setNomenPC] = useState('');
  const [nomenPR, setNomenPR] = useState('');
  const [nomenEX, setNomenEX] = useState('');
  const [nomenEC, setNomenEC] = useState('');
  const [newLabel, setNewLabel] = useState('');
  const [labelColumn, setLabelColumn] = useState([]);

  useEffect(() => {
    carregaPagina();
  }, []);

  function carregaPagina() {
    setLoading(true);

    var query =
      "SELECT * FROM `vpcharter_frota` INNER JOIN vpcharter_companhia ON vpcharter_frota.companhia = vpcharter_companhia.id INNER JOIN `vpcharter_fabricante` ON vpcharter_frota.`fabricante` = vpcharter_fabricante.id WHERE vpcharter_frota.id = '" +
      id +
      "' ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setFabricante(response.data[0]['aeronave']);
          setAeronave(response.data[0]['nomeAeronave']);
          seCompanhia(response.data[0]['nome']);
          setLogo(response.data[0]['logo']);
          setNomenPC(response.data[0]['nomen_pc']);
          setNomenPR(response.data[0]['nomen_pr']);
          setNomenEX(response.data[0]['nomen_ex']);
          setNomenEC(response.data[0]['nomen_ec']);
        } else {
          setTypeAlert('alert-danger');
          settitleAlert('Registro não encontrado!');
          setTimeout(() => setTypeAlert(''), 3000);
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM vpcharter_labelcolumn where fleet = '" + id + "' ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setLabelColumn(response.data);
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'primeiraClasse' AND `fileira` >= '0' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setprimeiraClasseFileiras(response.data);
          setTotalprimeiraClasseFileiras(response.data.length - 1);
        } else {
          setprimeiraClasseFileiras([]);
          setTotalprimeiraClasseFileiras('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'primeiraClasse' AND `coluna` != '' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setprimeiraClasseColunas(response.data);
          setTotalprimeiraClasseColunas(response.data.length);
        } else {
          setprimeiraClasseColunas([]);
          setTotalprimeiraClasseColunas('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'premium' AND `fileira` >= '0' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setPremiumFileiras(response.data);
          setTotalPremiumFileiras(response.data.length - 1);
        } else {
          setPremiumFileiras([]);
          setTotalPremiumFileiras('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'premium' AND `coluna` != '' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setPremiumColunas(response.data);
          setTotalPremiumColunas(response.data.length);
        } else {
          setPremiumColunas([]);
          setTotalPremiumColunas('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'executiva' AND `fileira` >= '0' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setExecutivaFileiras(response.data);
          setTotalExecutivaFileiras(response.data.length - 1);
        } else {
          setExecutivaFileiras([]);
          setTotalExecutivaFileiras('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'executiva' AND `coluna` != '' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setExecutivaColunas(response.data);
          setTotalExecutivaColunas(response.data.length);
        } else {
          setExecutivaColunas([]);
          setTotalExecutivaColunas('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'economica' AND `fileira` >= '0' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setEconomicaFileiras(response.data);
          setTotalEconomicaFileiras(response.data.length - 1);
        } else {
          setEconomicaFileiras([]);
          setTotalEconomicaFileiras('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = 'economica' AND `coluna` != '' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setEconomicaColunas(response.data);
          setTotalEconomicaColunas(response.data.length);
        } else {
          setEconomicaColunas([]);
          setTotalEconomicaColunas('');
        }
      })
      .catch((error) => alert(error));

    var query =
      "SELECT * FROM `vpcharter_caracteristicas_aeronave` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = '" +
      selectClass +
      "' LIMIT 1";
    var fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setServicoBordo(response.data[0]['servico_bordo']);
          setCaracteristicasAssentos(
            response.data[0]['caracteristicas_assento'],
          );
          setDistancia(response.data[0]['distancia']);
          setLargura(response.data[0]['largura']);
          setAngulo(response.data[0]['angulo']);
        } else {
          setServicoBordo('');
          setCaracteristicasAssentos('');
          setDistancia('');
          setLargura('');
          setAngulo('');
        }
      })
      .catch((error) => alert(error));

    var query =
      'SELECT * FROM `vpcharter_caracteristicasassentos` ORDER BY `caracteristica` ';
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setCaracteristicasassentosData(response.data);
      }
    });

    var query =
      'SELECT * FROM `vpcharter_servico_bordo` ORDER BY `servico_bordo` ';
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setServicoBordoData(response.data);
      }
    });

    var query =
      'SELECT * FROM `vpcharter_carcteristicas_assento_classe` ORDER BY `caracteristica` ';
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setAssentoClasseData(response.data);
      }
    });
    carregaCorredoresSaidasEmergencia();
    setLoading(false);
  }

  function carregaCorredoresSaidasEmergencia() {
    var query =
      "SELECT `id`, `coluna` FROM `vpcharter_corredores` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'primeiraClasse' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setCorredoresPrimC(response.data);
      }
    });
    var query =
      "SELECT `id`, `coluna` FROM `vpcharter_corredores` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'premium' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setCorredoresPremium(response.data);
      }
    });
    var query =
      "SELECT `id`, `coluna` FROM `vpcharter_corredores` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'executiva' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setCorredoresExecutiva(response.data);
      }
    });
    var query =
      "SELECT `id`, `coluna` FROM `vpcharter_corredores` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'economica' ORDER BY `coluna` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setCorredoresEconomica(response.data);
      }
    });

    var query =
      "SELECT `id`, `fileira` FROM `vpcharter_saidas_emergencia` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'primeiraClasse' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setSaidaEmergPrimC(response.data);
      }
    });
    var query =
      "SELECT `id`, `fileira` FROM `vpcharter_saidas_emergencia` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'premium' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setSaidaEmergPremium(response.data);
      }
    });
    var query =
      "SELECT `id`, `fileira` FROM `vpcharter_saidas_emergencia` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'executiva' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setSaidaEmergExecutiva(response.data);
      }
    });
    var query =
      "SELECT `id`, `fileira` FROM `vpcharter_saidas_emergencia` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = 'economica' ORDER BY `fileira` ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint + '/query', fData).then((response) => {
      if (response.data.length > 0) {
        setSaidaEmergEconomica(response.data);
      }
    });
  }

  function changeFileira(e, t, i) {
    setOpenModal2(true);
    setupdateFileira(e);
    setupdateTipoFileira(t);
    setupdateIdFileira(i);
  }

  function changeAssento(col, fil, classe) {
    /*
    setupdateTipoAssento('padrao')
    setupdateIdAssento('')
    var assento = col+fil;

    //Limpar lista
    for (var i = 0; i < caracteristicasassentosData.length; ++i) {
      var off = 'off'+i;
      var on = 'on'+i;
      document.getElementById(off).style.display = "block";
      document.getElementById(on).style.display = "none";
    }

    var query = "SELECT * FROM `vpcharter_mapaassentos` WHERE `aeronave` = '"+id+"' AND `categoria` = '"+classe+"' AND `assento` = '"+assento+"'  ";
    var fData = new FormData();
    fData.append('query', query);
    api.post(endpoint+'/query', fData)
    .then(response=> {
      if (response.data.length > 0) {
        var tipos = response.data[0]['tipoAssento'].substring(0, response.data[0]['tipoAssento'].length - 1).split(",").sort();
        for (var i = 0; i < tipos.length; ++i) {
          //Flag select
          var off = 'off'+tipos[i];
          var on = 'on'+tipos[i];
          document.getElementById(off).style.display = "none";
          document.getElementById(on).style.display = "block";
        }
        setCaracteristicasAssentoGrupo(response.data[0]['tipoAssento'])
        setupdateTipoAssento(response.data[0]['tipoAssento'])
        setupdateIdAssento(response.data[0]['id'])
      } 
    })
    .catch(error=> alert(error))

    setCaracteristicasAssentoGrupo('')
    setOpenModal3(true)
    setupdateAssento(assento)
    setupdateAssentoClasse(classe)
    */
  }

  function updateNewTipoAssento() {
    setOpenModal3(false);
    setLoading(true);

    setTimeout(function () {
      //var array = caracteristicasAssentoGrupo.substring(0, caracteristicasAssentoGrupo.length - 1).split(",").sort().toString();
      let array = caracteristicasAssentoGrupo.toString();

      if (caracteristicasAssentoGrupo.length > 0) {
        if (updateIdAssento) {
          var query =
            "update `vpcharter_mapaassentos` SET `tipoAssento` = '" +
            array +
            "' WHERE `vpcharter_mapaassentos`.`id` = '" +
            updateIdAssento +
            "' ";
        } else {
          var query =
            "INSERT INTO `vpcharter_mapaassentos` (`id`, `aeronave`, `categoria`, `assento`, `tipoAssento`) VALUES (NULL, '" +
            id +
            "', '" +
            updateAssentoClasse +
            "', '" +
            updateAssento +
            "', '" +
            array +
            "') ";
        }
        var fData = new FormData();
        fData.append('query', query);
        api
          .post(endpoint + '/query', fData)
          .then((response) => {
            if (response.status === 201) {
              setupdateIdAssento('');
              carregaPagina();
            } else {
              setTypeAlert('alert-danger');
              settitleAlert('Erro na Gravação!');
              setTimeout(() => setTypeAlert(''), 3000);
            }
          })
          .catch((error) => alert(error));
      } else {
        if (updateIdAssento) {
          var query =
            "DELETE FROM `vpcharter_mapaassentos` WHERE `vpcharter_mapaassentos`.`id` = '" +
            updateIdAssento +
            "'";
          var fData = new FormData();
          fData.append('query', query);
          api
            .post(endpoint + '/query', fData)
            .then((response) => {
              if (response.status === 201) {
                setupdateIdAssento('');
                carregaPagina();
              }
            })
            .catch((error) => alert(error));
        }
      }

      setLoading(false);
    }, 1000);
  }

  function updateLine(e, x) {
    setOpenModal2(false);
    setLoading(true);
    setTimeout(function () {
      if (x == 'fileira') {
        var query =
          "update `vpcharter_categoria_assentos` SET `tipo` = NULL WHERE `vpcharter_categoria_assentos`.`id` = '" +
          e +
          "' ";
      } else {
        var query =
          "update `vpcharter_categoria_assentos` SET `tipo` = '" +
          x +
          "' WHERE `vpcharter_categoria_assentos`.`id` = '" +
          e +
          "' ";
      }
      let fData = new FormData();
      fData.append('query', query);
      api
        .post(endpoint + '/query', fData)
        .then((response) => {
          if (response.status === 201) {
            carregaPagina();
          } else {
            setTypeAlert('alert-danger');
            settitleAlert('Erro na Gravação!');
            setTimeout(() => setTypeAlert(''), 3000);
          }
        })
        .catch((error) => alert(error));
      setLoading(false);
    }, 1000);
  }

  function delColuna() {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = '" +
      selectClass +
      "' AND `coluna` != '' ORDER BY `id` DESC LIMIT 1";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          let idDel = response.data[0]['id'];
          let query =
            "DELETE FROM `vpcharter_categoria_assentos` WHERE `vpcharter_categoria_assentos`.`id` = '" +
            idDel +
            "' ";
          let fData = new FormData();
          fData.append('query', query);
          api
            .post(endpoint + '/query', fData)
            .then((response) => {
              if (response.status === 201) {
                carregaPagina();
                setTimeout(() => setLoading(false), 5000);
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => alert(error));
  }

  function insertColuna() {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = '" +
      selectClass +
      "' AND `coluna` != '' ORDER BY `id` DESC LIMIT 1";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          let lastCol = response.data[0]['coluna'];
          var nextCol = String.fromCharCode(lastCol.charCodeAt(0) + 1);
        } else {
          var nextCol = 'A';
        }

        let query =
          "INSERT INTO `vpcharter_categoria_assentos` (`id`, `aeronave`, `categoria`, `coluna`, `fileira`, `tipo`) VALUES (NULL, '" +
          id +
          "',  '" +
          selectClass +
          "', '" +
          nextCol +
          "', NULL, NULL);";
        let fData = new FormData();
        fData.append('query', query);
        api
          .post(endpoint + '/query', fData)
          .then((response) => {
            if (response.status === 201) {
              carregaPagina();
              setTimeout(() => setLoading(false), 5000);
            }
          })
          .catch((error) => alert(error));
      })
      .catch((error) => alert(error));
  }

  function delFileira() {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = '" +
      selectClass +
      "' AND `fileira` >= '0' ORDER BY `id` DESC LIMIT 1";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          let idDel = response.data[0]['id'];
          let query =
            "DELETE FROM `vpcharter_categoria_assentos` WHERE `vpcharter_categoria_assentos`.`id` = '" +
            idDel +
            "' ";
          let fData = new FormData();
          fData.append('query', query);
          api
            .post(endpoint + '/query', fData)
            .then((response) => {
              if (response.status === 201) {
                carregaPagina();
                setTimeout(() => setLoading(false), 5000);
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => alert(error));
  }

  function insertFileira() {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_categoria_assentos` WHERE `aeronave` = '" +
      id +
      "' AND `categoria` = '" +
      selectClass +
      "' AND `fileira` >= '' ORDER BY `id` DESC LIMIT 1";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          var idAdd = parseInt(response.data[0]['fileira']) + 1;
        } else {
          var idAdd = 0;
        }

        let query =
          "INSERT INTO `vpcharter_categoria_assentos` (`id`, `aeronave`, `categoria`, `coluna`, `fileira`, `tipo`) VALUES (NULL, '" +
          id +
          "',  '" +
          selectClass +
          "', NULL, '" +
          idAdd +
          "', NULL);";
        let fData = new FormData();
        fData.append('query', query);
        api
          .post(endpoint + '/query', fData)
          .then((response) => {
            if (response.status === 201) {
              carregaPagina();
              setTimeout(() => setLoading(false), 5000);
            }
          })
          .catch((error) => alert(error));
      })
      .catch((error) => alert(error));
  }

  function changeSelectClass(e) {
    setLoading(true);
    setSelectClass(e);

    setTimeout(function () {
      carregaPagina();
      setServicoBordo('');

      let query =
        "SELECT * FROM `vpcharter_caracteristicas_aeronave` WHERE `aeronave` = '" +
        id +
        "' AND `classe` = '" +
        e +
        "' LIMIT 1";
      let fData = new FormData();
      fData.append('query', query);
      api
        .post(endpoint + '/query', fData)
        .then((response) => {
          if (response.data.length > 0) {
            setServicoBordo(response.data[0]['servico_bordo']);
            setCaracteristicasAssentos(
              response.data[0]['caracteristicas_assento'],
            );
            setDistancia(response.data[0]['distancia']);
            setLargura(response.data[0]['largura']);
            setAngulo(response.data[0]['angulo']);
            setLoading(false);
          } else {
            setServicoBordo('');
            setCaracteristicasAssentos('');
            setDistancia('');
            setLargura('');
            setAngulo('');
            setLoading(false);
          }
        })
        .catch((error) => alert(error));
    }, 1000);
  }

  function totalizadorFileiras() {
    let a = 0;
    let b = 0;
    let c = 0;
    let d = 0;
    if (totalprimeiraClasseFileiras) {
      a = totalprimeiraClasseFileiras;
    }
    if (totalPremiumFileiras) {
      b = totalPremiumFileiras;
    }
    if (totalExecutivaFileiras) {
      c = totalExecutivaFileiras;
    }
    if (totalEconomicaFileiras) {
      d = totalEconomicaFileiras;
    }
    let x = parseInt(a) + parseInt(b) + parseInt(c) + parseInt(d);
    return x;
  }

  function totalizadorColunas() {
    let a = 0;
    let b = 0;
    let c = 0;
    let d = 0;
    if (totalprimeiraClasseColunas) {
      a = totalprimeiraClasseColunas;
    }
    if (totalPremiumColunas) {
      b = totalPremiumColunas;
    }
    if (totalExecutivaColunas) {
      c = totalExecutivaColunas;
    }
    if (totalEconomicaColunas) {
      d = totalEconomicaColunas;
    }
    let x = parseInt(a) + parseInt(b) + parseInt(c) + parseInt(d);
    return x;
  }

  function contFileiraPremium(x) {
    let y = 0;
    if (totalprimeiraClasseFileiras) {
      y = totalprimeiraClasseFileiras;
    }
    return parseInt(x) + parseInt(y);
  }

  function contFileiraExecutiva(x) {
    let a = 0;
    let b = 0;
    if (totalprimeiraClasseFileiras) {
      a = totalprimeiraClasseFileiras;
    }
    if (totalPremiumFileiras) {
      b = totalPremiumFileiras - 1;
    }
    return parseInt(x) + parseInt(a) + parseInt(b);
  }

  function contFileiraEconomica(x) {
    let a = 0;
    let b = 0;
    let c = 0;
    if (totalprimeiraClasseFileiras) {
      a = totalprimeiraClasseFileiras;
    }
    if (totalPremiumFileiras) {
      b = totalPremiumFileiras - 1;
    }
    if (totalExecutivaFileiras) {
      c = totalExecutivaFileiras - 1;
    }
    return parseInt(x) + parseInt(a) + parseInt(b) + parseInt(c);
  }

  function updateCaracteristicas(s_bordo, c_assentos) {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_caracteristicas_aeronave` WHERE `aeronave` = '" +
      id +
      "' AND `classe` = '" +
      selectClass +
      "' LIMIT 1";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          let idupdate = response.data[0]['id'];
          var query =
            "update `vpcharter_caracteristicas_aeronave` SET `servico_bordo` = '" +
            s_bordo +
            "', `caracteristicas_assento` = '" +
            c_assentos +
            "', `distancia` = '" +
            distancia +
            "', `largura` = '" +
            largura +
            "', `angulo` = '" +
            angulo +
            "' WHERE `vpcharter_caracteristicas_aeronave`.`id` = '" +
            idupdate +
            "'; ";
          var fData = new FormData();
          fData.append('query', query);
          api
            .post(endpoint + '/query', fData)
            .then((response) => {
              if (response.status === 201) {
                setLoading(false);
                carregaPagina();
              }
            })
            .catch((error) => alert(error));
        } else {
          var query =
            "INSERT INTO `vpcharter_caracteristicas_aeronave` (`id`, `aeronave`, `servico_bordo`, `classe`, `caracteristicas_assento`, `distancia`, `largura`, `angulo`) VALUES (NULL, '" +
            id +
            "', '" +
            s_bordo +
            "', '" +
            selectClass +
            "', '" +
            c_assentos +
            "', '" +
            distancia +
            "', '" +
            largura +
            "', '" +
            angulo +
            "');";
          var fData = new FormData();
          fData.append('query', query);
          api
            .post(endpoint + '/query', fData)
            .then((response) => {
              if (response.status === 201) {
                setLoading(false);
                carregaPagina();
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => alert(error));
  }

  function gerarRota() {
    navigate(PagePath.AereoFrotaRotas.replace(':id', id));
  }

  function toggleCaracAssento(x, y) {
    let off = 'off' + x;
    let on = 'on' + x;
    if (y == 'off') {
      document.getElementById(off).style.display = 'none';
      document.getElementById(on).style.display = 'block';
    } else {
      document.getElementById(off).style.display = 'block';
      document.getElementById(on).style.display = 'none';
    }

    if (y == 'off') {
      setCaracteristicasAssentoGrupo(x + ',' + caracteristicasAssentoGrupo);
    } else {
      let remove = x + ',';
      setCaracteristicasAssentoGrupo(
        caracteristicasAssentoGrupo.replace(remove, ''),
      );
    }
  }

  function toggleServicoBordo(x) {
    let tt = servicoBordo.split(',');
    let found = '';
    found = tt.find((element) => element == x);
    let newservicoBordo = [];
    if (found) {
      let remove = x + ',';
      newservicoBordo = servicoBordo.replace(remove, '');
      setServicoBordo(servicoBordo.replace(remove, ''));
    } else {
      setServicoBordo(x + ',' + servicoBordo);
      newservicoBordo = x + ',' + servicoBordo;
    }
    updateCaracteristicas(newservicoBordo, caracteristicasAssentos);
  }

  function checkServicoBordo(x) {
    let tt = servicoBordo.split(',');
    let found = '';
    found = tt.find((element) => element == x);
    if (found) {
      return true;
    }
  }

  function checkAssentosClasse(x) {
    let tt = caracteristicasAssentos.split(',');
    let found = '';
    found = tt.find((element) => element == x);
    if (found) {
      return true;
    }
  }

  function toggleAssentosClasse(x) {
    let tt = caracteristicasAssentos.split(',');
    let found = '';
    found = tt.find((element) => element == x);
    let newcaract = [];

    if (found) {
      let remove = x + ',';
      newcaract = caracteristicasAssentos.replace(remove, '');
      setCaracteristicasAssentos(caracteristicasAssentos.replace(remove, ''));
    } else {
      newcaract = x + ',' + caracteristicasAssentos;
      setCaracteristicasAssentos(x + ',' + caracteristicasAssentos);
    }
    updateCaracteristicas(servicoBordo, newcaract);
  }

  function checkCorredor(x, y) {
    if (y == 'primeiraClasse') {
      for (var i = 0; i < corredoresPrimC.length; ++i) {
        if (corredoresPrimC[i]['coluna'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'premium') {
      for (var i = 0; i < corredoresPremium.length; ++i) {
        if (corredoresPremium[i]['coluna'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'executiva') {
      for (var i = 0; i < corredoresExecutiva.length; ++i) {
        if (corredoresExecutiva[i]['coluna'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'economica') {
      for (var i = 0; i < corredoresEconomica.length; ++i) {
        if (corredoresEconomica[i]['coluna'] == x) {
          return true;
        }
      }
      return false;
    }
  }

  function checkSaidaEmerg(x, y) {
    if (y == 'primeiraClasse') {
      for (var i = 0; i < saidaEmergPrimC.length; ++i) {
        if (saidaEmergPrimC[i]['fileira'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'premium') {
      for (var i = 0; i < saidaEmergPremium.length; ++i) {
        if (saidaEmergPremium[i]['fileira'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'executiva') {
      for (var i = 0; i < saidaEmergExecutiva.length; ++i) {
        if (saidaEmergExecutiva[i]['fileira'] == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'economica') {
      for (var i = 0; i < saidaEmergEconomica.length; ++i) {
        if (saidaEmergEconomica[i]['fileira'] == x) {
          return true;
        }
      }
      return false;
    }
  }

  function delCorredor(x) {
    setLoading(true);
    setTimeout(function () {
      let query = "DELETE FROM `vpcharter_corredores` WHERE `id` = '" + x + "'";
      let fData = new FormData();
      fData.append('query', query);
      api
        .post(endpoint + '/query', fData)
        .then(() => {
          setCorredoresPrimC([]);
          setCorredoresPremium([]);
          setCorredoresExecutiva([]);
          setCorredoresEconomica([]);
          carregaPagina();
        })
        .catch((error) => alert(error));
    }, 1000);
  }

  function insertCorredor() {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_corredores` WHERE  `aeronave` = '" +
      id +
      "' AND `classe` = '" +
      selectClass +
      "' AND  `coluna` = '" +
      selectCorredor +
      "' ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setLoading(false);
        } else {
          let query =
            "INSERT INTO `vpcharter_corredores` (`id`, `aeronave`, `classe`, `coluna`) VALUES (NULL, '" +
            id +
            "', '" +
            selectClass +
            "', '" +
            selectCorredor +
            "')";
          let fData = new FormData();
          fData.append('query', query);
          api
            .post(endpoint + '/query', fData)
            .then((response) => {
              if (response.status === 201) {
                setSelectCorredor('');
                carregaPagina();
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => alert(error));
  }

  function checkSelectCorredor(x, y) {
    if (y == 'primeiraClasse') {
      var found = corredoresPrimC.find(({ e }) => e === x);
      if (
        primeiraClasseColunas[primeiraClasseColunas.length - 1]['coluna'] == x
      ) {
        return true;
      }
    }
    if (y == 'premium') {
      var found = corredoresPremium.find(({ e }) => e === x);
      if (premiumColunas[premiumColunas.length - 1]['coluna'] == x) {
        return true;
      }
    }
    if (y == 'executiva') {
      var found = corredoresExecutiva.find(({ e }) => e === x);
      if (executivaColunas[executivaColunas.length - 1]['coluna'] == x) {
        return true;
      }
    }
    if (y == 'economica') {
      var found = corredoresEconomica.find(({ e }) => e === x);
      if (economicaColunas[economicaColunas.length - 1]['coluna'] == x) {
        return true;
      }
    }
    return false;
  }

  function checkSelectFileira(x, y) {
    if (y == 'primeiraClasse') {
      var found = saidaEmergPrimC.find(({ e }) => e == x);
      if (
        found ||
        primeiraClasseFileiras[primeiraClasseFileiras.length - 1]['fileira'] ==
          x
      ) {
        return true;
      }
    }
    if (y == 'premium') {
      var found = saidaEmergPremium.find(({ e }) => e == x);
      if (
        found ||
        premiumFileiras[premiumFileiras.length - 1]['fileira'] == x
      ) {
        return true;
      }
    }
    if (y == 'executiva') {
      var found = saidaEmergExecutiva.find(({ e }) => e == x);
      if (
        found ||
        executivaFileiras[executivaFileiras.length - 1]['fileira'] == x
      ) {
        return true;
      }
    }
    if (y == 'economica') {
      var found = saidaEmergEconomica.find(({ e }) => e === x);
      if (
        found ||
        economicaFileiras[economicaFileiras.length - 1]['fileira'] == x
      ) {
        return true;
      }
    }
    return false;
  }

  function soma(x, y, z, w) {
    return Number(x) + Number(y) + Number(z) + Number(w);
  }

  function insertSaidaEmerg() {
    setLoading(true);
    let query =
      "SELECT * FROM `vpcharter_saidas_emergencia` WHERE  `aeronave` = '" +
      id +
      "' AND `classe` = '" +
      selectClass +
      "' AND  `fileira` = '" +
      selectFileira +
      "' ";
    let fData = new FormData();
    fData.append('query', query);
    api
      .post(endpoint + '/query', fData)
      .then((response) => {
        if (response.data.length > 0) {
          setLoading(false);
        } else {
          let query =
            "INSERT INTO `vpcharter_saidas_emergencia` (`id`, `aeronave`, `classe`, `fileira`) VALUES (NULL, '" +
            id +
            "', '" +
            selectClass +
            "', '" +
            selectFileira +
            "')";
          let fData = new FormData();
          fData.append('query', query);
          api
            .post(endpoint + '/query', fData)
            .then((response) => {
              if (response.status === 201) {
                setSelectFileira('');
                carregaPagina();
              }
            })
            .catch((error) => alert(error));
        }
      })
      .catch((error) => alert(error));
  }

  function delSaidaEmerg(x) {
    setLoading(true);
    setTimeout(function () {
      let query =
        "DELETE FROM `vpcharter_saidas_emergencia` WHERE `id` = '" + x + "'";
      let fData = new FormData();
      fData.append('query', query);
      api
        .post(endpoint + '/query', fData)
        .then(() => {
          setSaidaEmergPrimC([]);
          setSaidaEmergPremium([]);
          setSaidaEmergExecutiva([]);
          setSaidaEmergEconomica([]);
          carregaPagina();
        })
        .catch((error) => alert(error));
    }, 1000);
  }

  function checkAssentoEspecial(x, y) {
    let totalPC = parseInt(primeiraClasseFileiras.length);
    let ultimo = '';

    if (x == 'primeiraClasse') {
      for (var i = 0; i < saidaEmergPrimC.length; ++i) {
        if (saidaEmergPrimC[i]['fileira'] == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'premium') {
      if (saidaEmergPrimC.length > 0) {
        let emergPC = parseInt(
          saidaEmergPrimC[saidaEmergPrimC.length - 1]['fileira'],
        );
        emergPC = emergPC + 1;
        if (totalPC == emergPC) {
          ultimo = emergPC;
        }
        if (ultimo == y) {
          return true;
        }
      }
      for (var i = 0; i < saidaEmergPremium.length; ++i) {
        if (saidaEmergPremium[i]['fileira'] == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'executiva') {
      if (saidaEmergPremium.length > 0) {
        var emergPR = parseInt(
          saidaEmergPremium[saidaEmergPremium.length - 1]['fileira'],
        );
        emergPR = emergPR + 1;
        if (emergPR == y) {
          return true;
        }
      } else if (saidaEmergPrimC.length > 0) {
        var emergPR = parseInt(
          saidaEmergPrimC[saidaEmergPrimC.length - 1]['fileira'],
        );
        emergPR = emergPR + 1;
        if (emergPR == emergPR) {
          ultimo = emergPR;
        }
        if (ultimo == y) {
          return true;
        }
      }
      for (var i = 0; i < saidaEmergExecutiva.length; ++i) {
        if (saidaEmergExecutiva[i]['fileira'] == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'economica') {
      if (saidaEmergExecutiva.length > 0) {
        var emergEX = parseInt(
          saidaEmergExecutiva[saidaEmergExecutiva.length - 1]['fileira'],
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      } else if (saidaEmergPremium.length > 0) {
        var emergEX = parseInt(
          saidaEmergPremium[saidaEmergPremium.length - 1]['fileira'],
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      } else if (saidaEmergPrimC.length > 0) {
        var emergEX = parseInt(
          saidaEmergPrimC[saidaEmergPrimC.length - 1]['fileira'],
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      }
      for (var i = 0; i < saidaEmergEconomica.length; ++i) {
        if (saidaEmergEconomica[i]['fileira'] == y - 1) {
          return true;
        }
      }
      return false;
    }
  }

  function changeLabelColumn(ncolumn, nclass) {
    setNewLabel(formatLabelColumn(ncolumn, nclass));
    setupdateLabelColumn(ncolumn);
    setupdateLabelColumnClass(nclass);
    setOpenModal(true);
  }
  function closeModal() {
    setOpenModal(false);
    setupdateLabelColumn('');
    setupdateLabelColumnClass('');
  }

  function validaForm(e) {
    setLoading(true);
    e.preventDefault();
    let updateLabel = false;
    for (let i = 0; i <= labelColumn.length - 1; i++) {
      if (
        labelColumn[i]['class'] == updateLabelColumnClass &&
        labelColumn[i]['column'] == updateLabelColumn
      ) {
        updateLabel = true;
      }
    }
    if (updateLabel) {
      if (updateLabelColumn == newLabel) {
        var query =
          "DELETE FROM `vpcharter_labelcolumn` WHERE `vpcharter_labelcolumn`.`fleet` = '" +
          id +
          "' and `vpcharter_labelcolumn`.`class` = '" +
          updateLabelColumnClass +
          "' and `vpcharter_labelcolumn`.`column` = '" +
          updateLabelColumn +
          "' ";
        var fData = new FormData();
        fData.append('query', query);
        api
          .post(endpoint + '/query', fData)
          .then((response) => {
            if (response.status === 201) {
              setTypeAlert('alert-success');
              settitleAlert('Sucesso!');
              setTxtAlert('Alterado com sucesso.');
              setTimeout(() => setTypeAlert(''), 3000);
              closeModal();
              setLoading(false);
              carregaPagina();
            }
          })
          .catch((error) => alert(error));
      } else {
        var query =
          "UPDATE `vpcharter_labelcolumn` SET `label` = '" +
          newLabel +
          "' WHERE `vpcharter_labelcolumn`.`fleet` = '" +
          id +
          "' and `vpcharter_labelcolumn`.`class` = '" +
          updateLabelColumnClass +
          "' and `vpcharter_labelcolumn`.`column` = '" +
          updateLabelColumn +
          "' ;";
        var fData = new FormData();
        fData.append('query', query);
        api
          .post(endpoint + '/query', fData)
          .then((response) => {
            if (response.status === 201) {
              setTypeAlert('alert-success');
              settitleAlert('Sucesso!');
              setTxtAlert('Alterado com sucesso.');
              setTimeout(() => setTypeAlert(''), 3000);
              closeModal();
              setLoading(false);
              carregaPagina();
            } else {
              setTypeAlert('alert-danger');
              settitleAlert('Erro na Gravação!');
              setTimeout(() => setTypeAlert(''), 3000);
              closeModal();
              setLoading(false);
              carregaPagina();
            }
          })
          .catch((error) => alert(error));
      }
    } else {
      var query =
        "INSERT INTO `vpcharter_labelcolumn` (`id`, `fleet`, `class`, `column`, `label`) VALUES (NULL, '" +
        id +
        "', '" +
        updateLabelColumnClass +
        "', '" +
        updateLabelColumn +
        "', '" +
        newLabel +
        "') ";
      var fData = new FormData();
      fData.append('query', query);
      api
        .post(endpoint + '/query', fData)
        .then((response) => {
          if (response.status === 201) {
            setTypeAlert('alert-success');
            settitleAlert('Sucesso!');
            setTxtAlert('Cadastrado com sucesso.');
            setTimeout(() => setTypeAlert(''), 3000);
            closeModal();
            setLoading(false);
            carregaPagina();
          } else {
            setTypeAlert('alert-danger');
            settitleAlert('Erro na Gravação!');
            setTimeout(() => setTypeAlert(''), 3000);
            closeModal();
            setLoading(false);
            carregaPagina();
          }
        })
        .catch((error) => alert(error));
    }
  }

  function formatLabelColumn(ncolumn, nclass) {
    for (let i = 0; i <= labelColumn.length - 1; i++) {
      if (
        labelColumn[i]['class'] == nclass &&
        labelColumn[i]['column'] == ncolumn
      ) {
        return labelColumn[i]['label'];
      }
    }
    return ncolumn;
  }

  return (
    <>
      {loading && <LoadingAnimation />}

      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div className="globalModal text-center">
          <span className="closeModal" onClick={() => closeModal()}>
            ✕
          </span>
          <h5>Alterar Label da Coluna</h5>
          <h5>
            <b>{updateLabelColumn}</b>&nbsp;•&nbsp;
            {updateLabelColumnClass == '1' ? nomenPC : ''}
            {updateLabelColumnClass == '2' ? nomenPR : ''}
            {updateLabelColumnClass == '3' ? nomenEX : ''}
            {updateLabelColumnClass == '4' ? nomenEC : ''}
          </h5>
          <div className="row mt-4">
            <form onSubmit={(e) => validaForm(e)}>
              <div className="col-12 ">
                <input
                  type="tex"
                  className="uppercase"
                  value={newLabel}
                  placeholder="Novo Label"
                  maxLength="1"
                  onChange={(e) => setNewLabel(e.target.value)}
                ></input>
              </div>
              <div className="col-12 modalAssento">
                <div className="col-12 mt-3">
                  <button type="submit">Alterar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={openModal2 ? 'bg_loading' : 'hide'}>
        <div className="globalModal text-center">
          <span className="closeModal" onClick={() => setOpenModal2(false)}>
            ✕
          </span>

          <h4>
            Alterar fileira <strong>{upadeFileira}</strong>
          </h4>
          <div className="row mt-4">
            <div className="col-12 modalAssento">
              <div
                className={
                  updateTipoFileira != 'janela'
                    ? 'selectTipoAssento'
                    : 'normalTipoAssento'
                }
              >
                <TfiLayoutWidthDefault />
                <input
                  type="radio"
                  name="tipo_fileira"
                  value="fileira"
                  onChange={(e) => setupdateTipoFileira(e.target.value)}
                  checked={updateTipoFileira != 'asa' ? 'checked' : ''}
                />
                <span>Padrão</span>
              </div>
              <div
                className={
                  updateTipoFileira == 'janela'
                    ? 'selectTipoAssento'
                    : 'normalTipoAssento'
                }
              >
                <GiPlaneWing />
                <input
                  type="radio"
                  name="tipo_fileira"
                  value="asa"
                  onChange={(e) => setupdateTipoFileira(e.target.value)}
                  checked={updateTipoFileira == 'asa' ? 'checked' : ''}
                />
                <span>Asa</span>
              </div>
              <div className="col-12 mt-3">
                <button
                  type="button"
                  onClick={() => updateLine(updateIdFileira, updateTipoFileira)}
                >
                  Alterar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={openModal3 ? 'bg_loading' : 'hide'}>
        <div className="globalModal text-center">
          <span className="closeModal" onClick={() => setOpenModal3(false)}>
            ✕
          </span>

          <h4>
            Características do Assento
            <br />
            <strong>{updateAssento}</strong> /&nbsp;
            {updateAssentoClasse == 'economica' ? 'Econômica' : ''}
            {updateAssentoClasse == 'executiva' ? 'Executiva' : ''}
            {updateAssentoClasse == 'premium' ? 'Premium' : ''}
            {updateAssentoClasse == 'primeiraClasse' ? 'Primeira Classe' : ''}
          </h4>
          <div className="row mt-4">
            <div className="col-12 modalAssento">
              {caracteristicasassentosData.map((data, index) => (
                <div className="caracteristicaAssento" key={index}>
                  <div>
                    <BsToggleOff
                      id={'off' + index}
                      onClick={() => toggleCaracAssento(index, 'off')}
                      className="BsToggleOff"
                    />
                    <BsToggleOn
                      id={'on' + index}
                      onClick={() => toggleCaracAssento(index, 'on')}
                    />
                  </div>
                  <span>{data.caracteristica}</span>
                </div>
              ))}

              <div className="row mt-4">
                <div className="col"></div>
                <div className="col-7">
                  <button type="button" onClick={() => updateNewTipoAssento()}>
                    Alterar
                  </button>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {txtAlert}
      </div>
      <div className="breadCrumb mx-auto my-0">
        <Link to={PagePath.Home} relative="path">
          <MdOutlineHome className="icoBread" />
          <BiSolidChevronRight className="caretRight" /> Home
        </Link>
        &nbsp;/&nbsp;
        <Link to={PagePath.Aereo} relative="path">
          Aéreo
        </Link>
        &nbsp;/&nbsp;
        <Link to={PagePath.AereoFrota} relative="path">
          Frota
        </Link>
        &nbsp;/&nbsp;
        <Link to={PagePath.AereoFrotaEditar.replace(':id', id)} relative="path">
          Aeronave
        </Link>
        &nbsp;/&nbsp;Mapa de Assentos
      </div>

      <div className="lineButtons row ">
        <div className="col">
          <h1>Mapa de Assentos</h1>
        </div>
      </div>

      <div className="contentAll">
        <div className="row">
          <div className={selectClass ? 'col-5 interCol' : 'hide'}>
            <div className="row  mt-4">
              <div className="col-12 titleAeronave">
                <img className={logo ? 'imgCia' : 'hide'} src={logo} />{' '}
                {logo ? '' : aeronave} <h4>{fabricante}</h4>
              </div>

              <div className="col-12 mt-3 ">
                <ul className="nav nav-pills nav-justified tabAeronave">
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('primeiraClasse')}
                  >
                    <span
                      className={
                        selectClass == 'primeiraClasse'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={
                        selectClass == 'primeiraClasse' ? 'page' : ''
                      }
                    >
                      {nomenPC}
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('premium')}
                  >
                    <span
                      className={
                        selectClass == 'premium'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={selectClass == 'premium' ? 'page' : ''}
                    >
                      {nomenPR}
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('executiva')}
                  >
                    <span
                      className={
                        selectClass == 'executiva'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={selectClass == 'executiva' ? 'page' : ''}
                    >
                      {nomenEX}
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('economica')}
                  >
                    <span
                      className={
                        selectClass == 'economica'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={selectClass == 'economica' ? 'page' : ''}
                    >
                      {nomenEC}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="col-12">
                {/*
                        <label>Categoria</label>
                        <h4>{selectClass}</h4>
                        <select name="select" value={selectClass} onChange={(e)=>changeSelectClass(e.target.value)}>
                          <option ></option>
                          <option value="primeiraClasse">Primeira Classe</option>
                          <option value="premium">Premium</option>
                          <option value="executiva">Executiva</option>
                          <option value="economica">Econômica</option>
                        </select>
                        */}
              </div>

              <div className={selectClass ? 'lineConfig mt-5' : 'hide'}>
                <div>Colunas</div>
                <button onClick={() => delColuna()} className="btnMorLess">
                  <BsDashCircleFill />
                </button>
                <h4 className={selectClass == 'primeiraClasse' ? '' : 'hide'}>
                  {totalprimeiraClasseColunas}
                </h4>
                <h4 className={selectClass == 'premium' ? '' : 'hide'}>
                  {totalPremiumColunas}
                </h4>
                <h4 className={selectClass == 'executiva' ? '' : 'hide'}>
                  {totalExecutivaColunas}
                </h4>
                <h4 className={selectClass == 'economica' ? '' : 'hide'}>
                  {totalEconomicaColunas}
                </h4>
                <button
                  onClick={() => insertColuna()}
                  className="btnMorLess"
                  title="Inserir"
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
              <div className={selectClass ? 'lineConfig' : 'hide'}>
                <div>Fileiras</div>
                <button onClick={() => delFileira()} className="btnMorLess">
                  <BsDashCircleFill />
                </button>
                <h4 className={selectClass == 'primeiraClasse' ? '' : 'hide'}>
                  {totalprimeiraClasseFileiras}
                </h4>
                <h4 className={selectClass == 'premium' ? '' : 'hide'}>
                  {totalPremiumFileiras}
                </h4>
                <h4 className={selectClass == 'executiva' ? '' : 'hide'}>
                  {totalExecutivaFileiras}
                </h4>
                <h4 className={selectClass == 'economica' ? '' : 'hide'}>
                  {totalEconomicaFileiras}
                </h4>
                <button
                  onClick={() => insertFileira()}
                  className="btnMorLess"
                  title="Inserir"
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <label>Corredores</label>
              </div>
              <div className="col">
                <select
                  className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  <option value=""> </option>
                  {primeiraClasseColunas.map((colun, index) => (
                    <option
                      value={colun.coluna}
                      key={index}
                      disabled={checkSelectCorredor(
                        colun.coluna,
                        'primeiraClasse',
                      )}
                    >
                      Após a coluna - {formatLabelColumn(colun.coluna, '1')}
                    </option>
                  ))}
                </select>

                <select
                  className={selectClass == 'premium' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  {premiumColunas.map((colun, index) => (
                    <option
                      value={colun.coluna}
                      key={index}
                      disabled={checkSelectCorredor(colun.coluna, 'premium')}
                    >
                      Após a coluna - {formatLabelColumn(colun.coluna, '2')}
                    </option>
                  ))}
                </select>

                <select
                  className={selectClass == 'executiva' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  {executivaColunas.map((colun, index) => (
                    <option
                      value={colun.coluna}
                      key={index}
                      disabled={checkSelectCorredor(colun.coluna, 'executiva')}
                    >
                      Após a coluna - {formatLabelColumn(colun.coluna, '3')}
                    </option>
                  ))}
                </select>

                <select
                  className={selectClass == 'economica' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  {economicaColunas.map((colun, index) => (
                    <option
                      value={colun.coluna}
                      key={index}
                      disabled={checkSelectCorredor(colun.coluna, 'economica')}
                    >
                      Após a coluna - {formatLabelColumn(colun.coluna, '4')}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <button
                  onClick={() => insertCorredor()}
                  className={
                    selectCorredor
                      ? 'btnMorLess'
                      : 'btnMorLess text-secondary opacity-50'
                  }
                  title="Inserir"
                  disabled={selectCorredor ? false : true}
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="listTable table-hover table">
                  <tbody
                    className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  >
                    {corredoresPrimC.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.coluna, '1')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'premium' ? '' : 'hide'}>
                    {corredoresPremium.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.coluna, '2')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'executiva' ? '' : 'hide'}>
                    {corredoresExecutiva.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.coluna, '3')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'economica' ? '' : 'hide'}>
                    {corredoresEconomica.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.coluna, '4')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <label>Saídas de Emergência</label>
              </div>
              <div className="col">
                <select
                  className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  <option value=""> </option>
                  {primeiraClasseFileiras.map((colun, index) => (
                    <option
                      value={index == 0 ? '' : colun.fileira}
                      key={index}
                      className={
                        index == '0' || colun.fileira == '0' ? 'hide' : ''
                      }
                    >
                      Após a fileira - {colun.fileira}
                    </option>
                  ))}
                </select>
                <select
                  className={selectClass == 'premium' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  {premiumFileiras.map((colun, index) => (
                    <option
                      value={
                        index == 0
                          ? ''
                          : soma(
                              colun.fileira,
                              primeiraClasseFileiras.length - 1,
                              0,
                              0,
                            )
                      }
                      key={index}
                    >
                      {colun.fileira == '0'
                        ? ' '
                        : 'Após a fileira - ' +
                          soma(
                            colun.fileira,
                            primeiraClasseFileiras.length - 1,
                            0,
                            0,
                          )}
                    </option>
                  ))}
                </select>

                <select
                  className={selectClass == 'executiva' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  {executivaFileiras.map((colun, index) => (
                    <option
                      value={
                        index == 0
                          ? ''
                          : soma(
                              colun.fileira,
                              primeiraClasseFileiras.length - 1,
                              premiumFileiras.length - 1,
                              0,
                            )
                      }
                      key={index}
                    >
                      {colun.fileira == '0'
                        ? ' '
                        : 'Após a fileira - ' +
                          soma(
                            colun.fileira,
                            primeiraClasseFileiras.length - 1,
                            premiumFileiras.length - 1,
                            0,
                          )}
                    </option>
                  ))}
                </select>

                <select
                  className={selectClass == 'economica' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  {economicaFileiras.map((colun, index) => (
                    <option
                      value={
                        index == 0
                          ? ''
                          : soma(
                              colun.fileira,
                              primeiraClasseFileiras.length - 1,
                              premiumFileiras.length - 1,
                              executivaFileiras.length - 1,
                            )
                      }
                      key={index}
                    >
                      {colun.fileira == '0'
                        ? ' '
                        : 'Após a fileira - ' +
                          soma(
                            colun.fileira,
                            primeiraClasseFileiras.length - 1,
                            premiumFileiras.length - 1,
                            executivaFileiras.length - 1,
                          )}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <button
                  onClick={() => insertSaidaEmerg()}
                  className={
                    selectFileira
                      ? 'btnMorLess'
                      : 'btnMorLess text-secondary opacity-50'
                  }
                  title="Inserir"
                  disabled={selectFileira ? false : true}
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="listTable table-hover table">
                  <tbody
                    className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  >
                    {saidaEmergPrimC.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data.fileira}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'premium' ? '' : 'hide'}>
                    {saidaEmergPremium.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data.fileira}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'executiva' ? '' : 'hide'}>
                    {saidaEmergExecutiva.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data.fileira}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'economica' ? '' : 'hide'}>
                    {saidaEmergEconomica.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data.coluna}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6">
                <label>Serviço de Bordo</label>
                {servicoBordoData.map((data, index) => (
                  <div className="toggleList" key={index}>
                    <div className={data.servico_bordo ? '' : 'hide'}>
                      <BsToggleOff
                        className={
                          checkServicoBordo(data.id) ? 'hide' : 'BsToggleOff'
                        }
                        onClick={() => toggleServicoBordo(data.id)}
                      />
                      <BsToggleOn
                        className={checkServicoBordo(data.id) ? '' : 'hide'}
                        onClick={() => toggleServicoBordo(data.id)}
                      />
                    </div>
                    <span>{data.servico_bordo}</span>
                  </div>
                ))}
              </div>
              <div className="col-6">
                <label>Características Assentos</label>
                {assentoClasseData.map((data, index) => (
                  <div className="toggleList" key={index}>
                    <div className={data.caracteristica ? '' : 'hide'}>
                      <BsToggleOff
                        className={
                          checkAssentosClasse(data.id) ? 'hide' : 'BsToggleOff'
                        }
                        onClick={() => toggleAssentosClasse(data.id)}
                      />
                      <BsToggleOn
                        className={checkAssentosClasse(data.id) ? '' : 'hide'}
                        onClick={() => toggleAssentosClasse(data.id)}
                      />
                    </div>
                    <span>{data.caracteristica}</span>
                  </div>
                ))}
              </div>
            </div>
            {/*
                    <div className='row mt-4'>
                      <div className='col-4'>
                        <label>Distância (cm)</label>
                        <input type='number' min="0" value={distancia} onChange={(e)=>setDistancia(e.target.value)}></input>
                      </div>
                      <div className='col-4'>
                        <label>Largura (cm)</label>
                        <input type='number' min="0" value={largura} onChange={(e)=>setLargura(e.target.value)}></input>
                      </div>
                      <div className='col-4'>
                        <label>Ângulo (º)</label>
                        <input type='number' min="0" max="90" value={angulo} onChange={(e)=>setAngulo(e.target.value)}></input>
                      </div>
                    </div>


                    <div className='row mt-4'>
                      <div className='col-12'>
                          <button type='button' onClick={()=>updateCaracteristicas()} ><span className={loading ? 'hide' : ''}><BsCheckLg /> Salvar</span><span className={loading ? 'loader' : 'hide'}></span></button>
                      </div>
                    </div>
                    */}

            <div className="row">
              <div className="col-12">
                <button type="button" onClick={() => gerarRota()}>
                  <span>Criar rotas</span>
                </button>
              </div>
            </div>
          </div>

          <div className={!selectClass ? 'col-5 interCol p-5' : 'hide'}>
            <div className="col-12 titleAeronave">
              <img className={logo ? 'imgCia' : 'hide'} src={logo} />{' '}
              {logo ? '' : aeronave} <h4>{fabricante}</h4>
            </div>

            <div className="mb-0 mt-5">
              <h5>
                <b>Por favor, selecione a Categoria.</b>
              </h5>
            </div>

            <div className="col-12 mt-3 ">
              <ul className="nav nav-pills nav-justified tabAeronave">
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('primeiraClasse')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'primeiraClasse' ? 'page' : ''}
                  >
                    {nomenPC}
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('premium')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'premium' ? 'page' : ''}
                  >
                    {nomenPR}
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('executiva')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'executiva' ? 'page' : ''}
                  >
                    {nomenEX}
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('economica')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'economica' ? 'page' : ''}
                  >
                    {nomenEC}
                  </span>
                </li>
              </ul>
            </div>

            <div className="row">
              <div className="col-12">
                <button type="button" onClick={() => gerarRota()}>
                  <span>Criar rotas</span>
                </button>
              </div>
            </div>
          </div>

          <div className="col-7">
            <table className="mapaAssentos">
              <thead>
                <tr>
                  <td></td>
                  <td colSpan={totalizadorColunas()}>
                    <div className="frenteAeronave"></div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div className="saidaEmergencia">
                      <div className="exit">EXIT</div>
                      <div className="exit">EXIT</div>
                    </div>
                  </td>
                </tr>

                {/*Primeira Classe ----------------------------- */}
                <tr className={totalprimeiraClasseFileiras > 1 ? '' : 'hide'}>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'primeiraClasse'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {totalprimeiraClasseFileiras > 1 ? nomenPC : ''}
                    </div>
                  </td>
                </tr>

                {primeiraClasseFileiras.map((line, id) => (
                  <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={line.tipo == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() =>
                            changeFileira(line.fileira, line.tipo, line.id)
                          }
                        >
                          {line.fileira}
                        </button>
                      </div>
                    </td>
                    <td
                      className={line.tipo == 'asa' ? 'asaDir' : 'tdBordaDir'}
                    >
                      <div
                        className={
                          line.tipo == 'emergencia'
                            ? 'hide'
                            : selectClass == 'primeiraClasse'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {primeiraClasseColunas.map((colun, index) => (
                          <div key={index} className="flex">
                            <div className="assentos">
                              <div
                                className={
                                  id == '0' ? 'hide' : 'primeiraClasse'
                                }
                                onClick={() =>
                                  changeAssento(
                                    colun.coluna,
                                    line.fileira,
                                    'primeiraClasse',
                                  )
                                }
                              >
                                <div data-title="Assento de Emergência">
                                  <MdOutlineStar
                                    className={
                                      checkAssentoEspecial(
                                        'primeiraClasse',
                                        line.fileira,
                                      )
                                        ? 'starEspecial'
                                        : 'hide'
                                    }
                                  />
                                </div>
                              </div>
                              <button
                                className={
                                  line.fileira == '0' ? 'btnAssentos' : 'hide'
                                }
                                onClick={() =>
                                  changeLabelColumn(colun.coluna, '1')
                                }
                              >
                                <span data-title="Alterar Label">
                                  {formatLabelColumn(colun.coluna, '1')}
                                </span>
                              </button>
                            </div>
                            <div
                              className={
                                checkCorredor(colun.coluna, 'primeiraClasse')
                                  ? 'assentos'
                                  : 'hide'
                              }
                            >
                              <div className="primeiraClasseCorredor"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(line.fileira, 'primeiraClasse')
                            ? line.tipo == 'asa'
                              ? selectClass == 'primeiraClasse'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'primeiraClasse'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {/*Premium ----------------------------- */}
                <tr className={totalPremiumFileiras > 1 ? '' : 'hide'}>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'premium'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {totalPremiumFileiras > 1 ? nomenPR : ''}
                    </div>
                  </td>
                </tr>
                {premiumFileiras.map((line, id) => (
                  <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={line.tipo == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() =>
                            changeFileira(
                              contFileiraPremium(line.fileira),
                              line.tipo,
                              line.id,
                            )
                          }
                        >
                          {contFileiraPremium(line.fileira)}
                        </button>
                      </div>
                    </td>
                    <td
                      className={line.tipo == 'asa' ? 'asaDir' : 'tdBordaDir'}
                    >
                      <div
                        className={
                          line.tipo == 'emergencia'
                            ? 'hide'
                            : selectClass == 'premium'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {premiumColunas.map((colun, index) => (
                          <div key={index} className="flex">
                            <div className="assentos">
                              <div
                                className={id == '0' ? 'hide' : 'premium'}
                                onClick={() =>
                                  changeAssento(
                                    colun.coluna,
                                    contFileiraPremium(line.fileira),
                                    'premium',
                                  )
                                }
                              >
                                <span
                                  className={
                                    colun.tipo == 'corredor' ? 'hide' : ''
                                  }
                                >
                                  <div data-title="Assento de Emergência">
                                    <MdOutlineStar
                                      className={
                                        checkAssentoEspecial(
                                          'premium',
                                          contFileiraPremium(line.fileira),
                                        )
                                          ? 'starEspecial'
                                          : 'hide'
                                      }
                                    />
                                  </div>
                                </span>
                              </div>
                              <button
                                className={id == '0' ? 'btnAssentos' : 'hide'}
                                onClick={() =>
                                  changeLabelColumn(colun.coluna, '2')
                                }
                              >
                                <span data-title="Alterar Label">
                                  {formatLabelColumn(colun.coluna, '2')}
                                </span>
                              </button>
                            </div>
                            <div
                              className={
                                checkCorredor(colun.coluna, 'premium')
                                  ? 'assentos'
                                  : 'hide'
                              }
                            >
                              <div className="premiumCorredor"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(
                            contFileiraPremium(line.fileira),
                            'premium',
                          )
                            ? line.tipo == 'asa'
                              ? selectClass == 'premium'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'premium'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {/*Executiva ----------------------------- */}
                <tr className={totalExecutivaFileiras > 1 ? '' : 'hide'}>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'executiva'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {totalExecutivaFileiras > 1 ? nomenEX : ''}
                    </div>
                  </td>
                </tr>
                {executivaFileiras.map((line, id) => (
                  <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={line.tipo == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() =>
                            changeFileira(
                              contFileiraExecutiva(line.fileira),
                              line.tipo,
                              line.id,
                            )
                          }
                        >
                          {contFileiraExecutiva(line.fileira)}
                        </button>
                      </div>
                    </td>
                    <td
                      className={line.tipo == 'asa' ? 'asaDir' : 'tdBordaDir'}
                    >
                      <div
                        className={
                          line.tipo == 'emergencia'
                            ? 'hide'
                            : selectClass == 'executiva'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {executivaColunas.map((colun, index) => (
                          <div key={index} className="flex">
                            <div className="assentos">
                              <div
                                className={id == '0' ? 'hide' : 'executiva'}
                                onClick={() =>
                                  changeAssento(
                                    colun.coluna,
                                    contFileiraExecutiva(line.fileira),
                                    'executiva',
                                  )
                                }
                              >
                                <span
                                  className={
                                    colun.tipo == 'corredor' ? 'hide' : ''
                                  }
                                >
                                  <div data-title="Assento de Emergência">
                                    <MdOutlineStar
                                      className={
                                        checkAssentoEspecial(
                                          'executiva',
                                          contFileiraExecutiva(line.fileira),
                                        )
                                          ? 'starEspecial'
                                          : 'hide'
                                      }
                                    />
                                  </div>
                                </span>
                              </div>
                              <button
                                className={id == '0' ? 'btnAssentos' : 'hide'}
                                onClick={() =>
                                  changeLabelColumn(colun.coluna, '3')
                                }
                              >
                                <span data-title="Alterar Label">
                                  {formatLabelColumn(colun.coluna, '3')}
                                </span>
                              </button>
                            </div>
                            <div
                              className={
                                checkCorredor(colun.coluna, 'executiva')
                                  ? 'assentos'
                                  : 'hide'
                              }
                            >
                              <div className="executivaCorredor"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(
                            contFileiraExecutiva(line.fileira),
                            'executiva',
                          )
                            ? line.tipo == 'asa'
                              ? selectClass == 'executiva'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'premium'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {/*Econômica ----------------------------- */}
                <tr className={totalEconomicaFileiras > 1 ? '' : 'hide'}>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'economica'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {totalEconomicaFileiras > 1 ? nomenEC : ''}
                    </div>
                  </td>
                </tr>
                {economicaFileiras.map((line, id) => (
                  <tr key={id} className={id == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={line.tipo == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={id == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() =>
                            changeFileira(
                              contFileiraEconomica(line.fileira),
                              line.tipo,
                              line.id,
                            )
                          }
                        >
                          {contFileiraEconomica(line.fileira)}
                        </button>
                      </div>
                    </td>
                    <td
                      className={line.tipo == 'asa' ? 'asaDir' : 'tdBordaDir'}
                    >
                      <div
                        className={
                          line.tipo == 'emergencia'
                            ? 'hide'
                            : selectClass == 'economica'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {economicaColunas.map((colun, index) => (
                          <div key={index} className="flex">
                            <div className="assentos">
                              <div
                                className={id == '0' ? 'hide' : 'economica'}
                                onClick={() =>
                                  changeAssento(
                                    colun.coluna,
                                    contFileiraEconomica(line.fileira),
                                    'economica',
                                  )
                                }
                              >
                                <span
                                  className={
                                    colun.tipo == 'corredor' ? 'hide' : ''
                                  }
                                >
                                  <div data-title="Assento de Emergência">
                                    <MdOutlineStar
                                      className={
                                        checkAssentoEspecial(
                                          'economica',
                                          contFileiraEconomica(line.fileira),
                                        )
                                          ? 'starEspecial'
                                          : 'hide'
                                      }
                                    />
                                  </div>
                                </span>
                              </div>
                              <button
                                className={id == '0' ? 'btnAssentos' : 'hide'}
                                onClick={() =>
                                  changeLabelColumn(colun.coluna, '4')
                                }
                              >
                                <span data-title="Alterar Label">
                                  {formatLabelColumn(colun.coluna, '4')}
                                </span>
                              </button>
                            </div>
                            <div
                              className={
                                checkCorredor(colun.coluna, 'economica')
                                  ? 'assentos'
                                  : 'hide'
                              }
                            >
                              <div className="economicaCorredor"></div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(
                            contFileiraEconomica(line.fileira),
                            'economica',
                          )
                            ? line.tipo == 'asa'
                              ? selectClass == 'economica'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'premium'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {minFileiras.map((line, index) => (
                  <tr
                    key={index}
                    className={
                      index > minFileiras.length - totalizadorFileiras()
                        ? 'hide'
                        : ''
                    }
                  >
                    <td className="tdBordaDir"></td>
                    <td colSpan={totalizadorColunas()} className="tdBordaDir">
                      <div className="divideClass"></div>
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div className="saidaEmergencia fundoEmergencia">
                      <div className="exit">EXIT</div>
                      <div className="exit">EXIT</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={totalizadorColunas()}>
                    <div className="frundoAeronave"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
