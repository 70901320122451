import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import HeaderCheckout from '@components/HeaderCheckout';
import { useAuthentication } from '@stores/authentication';
import { PagePath } from '../pages-config';

export function Checkout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { session, methods } = useAuthentication();

  useEffect(() => {
    const validate = async () => {
      const tokenIsValid = await methods.validateToken();
      if (!tokenIsValid) {
        navigate('/');
      } else {
        if (location.pathname === '/') {
          navigate(PagePath.Home);
        }
      }
    };

    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    session && (
      <>
        <HeaderCheckout />
        <Outlet />
      </>
    )
  );
}
