import { Suppliers } from '@system/enums';

export const convertStatusReservation = (status, selectedSupplier) => {
  if (selectedSupplier === Suppliers.Azul) {
    const data = {
      Default: {
        type: 'info',
        value: 'Padrão',
      },
      Hold: {
        type: 'success',
        value: 'Reservada',
      },
      Confirmed: {
        type: 'success',
        value: 'Confirmada',
      },
      Closed: {
        type: 'error',
        value: 'Fechada',
      },
      HoldCanceled: {
        type: 'error',
        value: 'Cancelada',
      },
      PendingArchive: {
        type: 'error',
        value: 'Pendente',
      },
      Archived: {
        type: 'warning',
        value: 'Arquivada',
      },
    };
    return data[status] ?? data['Default'];
  }

  return { type: 'info', value: status };
};
